import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import HeaderHome from './layout/header-home';
import Marquee from "react-fast-marquee";
import { isLogged } from '../../Services/Authentication';

const InterfaceHome: React.FunctionComponent<{}> = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handlClick = () => {
        if (isLogged()) {
            navigate('/Dashboard')
        } else {
            navigate('/pricing')
        }
    }

    return (
        <div className="main--slider ">
            <HeaderHome />
            <div className="row slide justify-content-center dir-rtl">
                <div className="caption">
                    <div className='tag-home mb-4'>{t("Most powerful AI for interior design")}</div>
                    <h1>{t("Transform Your Home")} <div className="logo-container">
                        <span className="logo-text">{t("Instantly")}</span>
                        <img src="/assets/images-v2/Squiggle Mini 13.png" className="logo-line" alt="Squiggle" />
                    </div> {t("with AI")}</h1>
                    <p>{t("Redesign, furnish, landscape, and convert")}</p>
                    <button className="button-login height-button mar-top-40" onClick={handlClick}>{t("GetStarted for Free")}</button>
                </div>
            </div>
            <div className='list-tags' >
                <Marquee pauseOnClick={true} className="tags " >
                    <p className='dir-rtl'><svg width="14" height="14" className="marg-right-10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.0888672" y="0.116699" width="13" height="12.9074" rx="6.45368" fill="white" />
                        <path d="M5.63944 7.79908L8.93184 4.50668C9.00953 4.42899 9.10018 4.39014 9.20378 4.39014C9.30737 4.39014 9.39802 4.42899 9.47571 4.50668C9.55341 4.58438 9.59226 4.67671 9.59226 4.78367C9.59226 4.89063 9.55341 4.98283 9.47571 5.06027L5.91138 8.63432C5.83368 8.71201 5.74304 8.75086 5.63944 8.75086C5.53584 8.75086 5.4452 8.71201 5.3675 8.63432L3.69702 6.96384C3.61933 6.88614 3.58203 6.79394 3.58514 6.68724C3.58825 6.58054 3.62878 6.48821 3.70674 6.41025C3.78469 6.33229 3.87702 6.29345 3.98372 6.29371C4.09043 6.29396 4.18263 6.33281 4.26032 6.41025L5.63944 7.79908Z" fill="black" />
                    </svg>
                        {t("+100M Images Processed")}</p>
                    <p className='dir-rtl'><svg width="14" height="14" className="marg-right-10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.0888672" y="0.116699" width="13" height="12.9074" rx="6.45368" fill="white" />
                        <path d="M5.63944 7.79908L8.93184 4.50668C9.00953 4.42899 9.10018 4.39014 9.20378 4.39014C9.30737 4.39014 9.39802 4.42899 9.47571 4.50668C9.55341 4.58438 9.59226 4.67671 9.59226 4.78367C9.59226 4.89063 9.55341 4.98283 9.47571 5.06027L5.91138 8.63432C5.83368 8.71201 5.74304 8.75086 5.63944 8.75086C5.53584 8.75086 5.4452 8.71201 5.3675 8.63432L3.69702 6.96384C3.61933 6.88614 3.58203 6.79394 3.58514 6.68724C3.58825 6.58054 3.62878 6.48821 3.70674 6.41025C3.78469 6.33229 3.87702 6.29345 3.98372 6.29371C4.09043 6.29396 4.18263 6.33281 4.26032 6.41025L5.63944 7.79908Z" fill="black" />
                    </svg>
                        {t("25+ Trending Styles")}</p>
                    <p className='dir-rtl'><svg width="14" height="14" className="marg-right-10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.0888672" y="0.116699" width="13" height="12.9074" rx="6.45368" fill="white" />
                        <path d="M5.63944 7.79908L8.93184 4.50668C9.00953 4.42899 9.10018 4.39014 9.20378 4.39014C9.30737 4.39014 9.39802 4.42899 9.47571 4.50668C9.55341 4.58438 9.59226 4.67671 9.59226 4.78367C9.59226 4.89063 9.55341 4.98283 9.47571 5.06027L5.91138 8.63432C5.83368 8.71201 5.74304 8.75086 5.63944 8.75086C5.53584 8.75086 5.4452 8.71201 5.3675 8.63432L3.69702 6.96384C3.61933 6.88614 3.58203 6.79394 3.58514 6.68724C3.58825 6.58054 3.62878 6.48821 3.70674 6.41025C3.78469 6.33229 3.87702 6.29345 3.98372 6.29371C4.09043 6.29396 4.18263 6.33281 4.26032 6.41025L5.63944 7.79908Z" fill="black" />
                    </svg>
                        {t("+83% Buyer Interest")}</p>
                    <p className='dir-rtl'><svg width="14" height="14" className="marg-right-10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.0888672" y="0.116699" width="13" height="12.9074" rx="6.45368" fill="white" />
                        <path d="M5.63944 7.79908L8.93184 4.50668C9.00953 4.42899 9.10018 4.39014 9.20378 4.39014C9.30737 4.39014 9.39802 4.42899 9.47571 4.50668C9.55341 4.58438 9.59226 4.67671 9.59226 4.78367C9.59226 4.89063 9.55341 4.98283 9.47571 5.06027L5.91138 8.63432C5.83368 8.71201 5.74304 8.75086 5.63944 8.75086C5.53584 8.75086 5.4452 8.71201 5.3675 8.63432L3.69702 6.96384C3.61933 6.88614 3.58203 6.79394 3.58514 6.68724C3.58825 6.58054 3.62878 6.48821 3.70674 6.41025C3.78469 6.33229 3.87702 6.29345 3.98372 6.29371C4.09043 6.29396 4.18263 6.33281 4.26032 6.41025L5.63944 7.79908Z" fill="black" />
                    </svg>
                        {t("1-Click Transformation")} </p>
                    <p className='dir-rtl'><svg width="14" height="14" className="marg-right-10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.0888672" y="0.116699" width="13" height="12.9074" rx="6.45368" fill="white" />
                        <path d="M5.63944 7.79908L8.93184 4.50668C9.00953 4.42899 9.10018 4.39014 9.20378 4.39014C9.30737 4.39014 9.39802 4.42899 9.47571 4.50668C9.55341 4.58438 9.59226 4.67671 9.59226 4.78367C9.59226 4.89063 9.55341 4.98283 9.47571 5.06027L5.91138 8.63432C5.83368 8.71201 5.74304 8.75086 5.63944 8.75086C5.53584 8.75086 5.4452 8.71201 5.3675 8.63432L3.69702 6.96384C3.61933 6.88614 3.58203 6.79394 3.58514 6.68724C3.58825 6.58054 3.62878 6.48821 3.70674 6.41025C3.78469 6.33229 3.87702 6.29345 3.98372 6.29371C4.09043 6.29396 4.18263 6.33281 4.26032 6.41025L5.63944 7.79908Z" fill="black" />
                    </svg>
                        {t("Free to Start")}</p>
                </Marquee>

            </div>
            <div className="image-cont">
                <img src="/assets/images-v2/BEFORE-1.webp" alt="Interior Design" className="section-image" />
            </div>
        </div>
    )
}
export default InterfaceHome;
