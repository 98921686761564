import { useLocation, useNavigate } from "react-router-dom";
import { isLogged, logout } from "../../../Services/Authentication";
import { useTranslation } from "react-i18next";


const Sidebar: React.FunctionComponent<{}> = (props) => {


    let location = useLocation().pathname.split('/');
    const navigate =useNavigate();
    const { t } = useTranslation();
    const pathname = location[1];
    const logoutUser = () => {
        logout(); 
        navigate("/login");
    }


    return (
        <div className="redisgn">
            <div className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="navbar-nav sidenav">
                    <li className="nav-logo text-center">
                        <a className="nav-link" href="/home">
                        <img width="221" height="66" src="/assets/images-v2/logo-design-white.svg" className="img-responsive" alt="logo" />
                        </a>
                    </li>
                    <li className={[pathname == "Dashboard" ? "active" : "", "nav-item"].join(" ")}>
                        <a className="nav-link" href="/Dashboard">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M22.9985 5.6834C22.9985 8.16271 20.9878 10.1734 18.5085 10.1734C16.0292 10.1734 14.0195 8.16271 14.0195 5.6834C14.0195 3.20409 16.0292 1.19336 18.5085 1.19336C20.9878 1.19336 22.9985 3.20409 22.9985 5.6834Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.97897 5.6834C9.97897 8.16271 7.96935 10.1734 5.48894 10.1734C3.01073 10.1734 1 8.16271 1 5.6834C1 3.20409 3.01073 1.19336 5.48894 1.19336C7.96935 1.19336 9.97897 3.20409 9.97897 5.6834Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M22.9985 18.6248C22.9985 21.1041 20.9878 23.1137 18.5085 23.1137C16.0292 23.1137 14.0195 21.1041 14.0195 18.6248C14.0195 16.1455 16.0292 14.1348 18.5085 14.1348C20.9878 14.1348 22.9985 16.1455 22.9985 18.6248Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M9.97897 18.6248C9.97897 21.1041 7.96935 23.1137 5.48894 23.1137C3.01073 23.1137 1 21.1041 1 18.6248C1 16.1455 3.01073 14.1348 5.48894 14.1348C7.96935 14.1348 9.97897 16.1455 9.97897 18.6248Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className="menu-title">{t("Dashboard")}</span>
                        </a>
                    </li>
                    <li className={[pathname == "History" ? "active" : "", "nav-item"].join(" ")}>
                        <a className="nav-link" href="/History">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.90901 10.0371V17.5738" stroke="#E8E8EC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12.0379 6.43164V17.5761" stroke="#E8E8EC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.0809 14.0215V17.5759" stroke="#E8E8EC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.07422 12.0373C1.07422 3.81559 3.81559 1.07422 12.0373 1.07422C20.2591 1.07422 23.0004 3.81559 23.0004 12.0373C23.0004 20.2591 20.2591 23.0004 12.0373 23.0004C3.81559 23.0004 1.07422 20.2591 1.07422 12.0373Z" stroke="#E8E8EC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span className="menu-title">{t("History")}</span>
                        </a>
                    </li>
                
                    {isLogged() && <li className="nav-log-out">
                        <a className="nav-link" href="#" onClick={logoutUser}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 7L9 12L14 17" stroke="#F7FBFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9 12L21 12" stroke="#F7FBFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19L3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="#F7FBFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                            <span className="menu-title">{t("Log Out")}</span>
                        </a>
                    </li>}
                </ul>
            </div>
        </div>

    )
}
export default Sidebar