import React from "react";
import { useTranslation } from "react-i18next";

const Footer: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    return (
        <footer id="footer">
            <div className="footer-holder dir-rtl">
                <div className="container">
                    <div className="row">
                        <div className=" col-md-4">
                            <img src="/assets/images-v2/logo-design-white.svg" className="img-fluid logo-footer" alt="logo" />
                                <ul className="socail-network">
                                    <li>
                                        <a href="https://www.pinterest.com/designanyroom" target="_blank">
                                            <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13.7261 32.5992C15.1353 33.0249 16.5592 33.2745 18.0713 33.2745C21.9645 33.2745 25.6984 31.7279 28.4513 28.9749C31.2043 26.2219 32.7509 22.4881 32.7509 18.5948C32.7509 16.6671 32.3712 14.7582 31.6335 12.9772C30.8958 11.1961 29.8145 9.57786 28.4513 8.21473C27.0882 6.8516 25.4699 5.7703 23.6889 5.03258C21.9079 4.29486 19.999 3.91516 18.0713 3.91516C16.1435 3.91516 14.2346 4.29486 12.4536 5.03258C10.6726 5.7703 9.0543 6.8516 7.69117 8.21473C4.9382 10.9677 3.3916 14.7015 3.3916 18.5948C3.3916 24.8337 7.31107 30.1917 12.8453 32.3056C12.7132 31.1606 12.5811 29.2669 12.8453 27.9604L14.5335 20.7087C14.5335 20.7087 14.1077 19.8573 14.1077 18.5067C14.1077 16.4809 15.3702 14.9689 16.8088 14.9689C18.0713 14.9689 18.6584 15.8938 18.6584 17.0828C18.6584 18.3453 17.8217 20.1509 17.396 21.8831C17.1464 23.3217 18.1593 24.5841 19.6273 24.5841C22.2403 24.5841 24.2661 21.795 24.2661 17.8608C24.2661 14.3377 21.7412 11.9303 18.1153 11.9303C13.9756 11.9303 11.5388 15.013 11.5388 18.2572C11.5388 19.5196 11.9498 20.7968 12.6251 21.6335C12.7572 21.7216 12.7572 21.839 12.7132 22.0592L12.2875 23.6593C12.2875 23.9088 12.126 23.9969 11.8764 23.8208C9.99744 22.9987 8.91115 20.327 8.91115 18.1691C8.91115 13.5303 12.1994 9.31727 18.541 9.31727C23.5908 9.31727 27.5249 12.9431 27.5249 17.7581C27.5249 22.8079 24.3982 26.8595 19.9209 26.8595C18.497 26.8595 17.1024 26.0961 16.6033 25.2007L15.6198 28.6797C15.2821 29.9422 14.3573 31.6303 13.7261 32.6432V32.5992Z" fill="white" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.tiktok.com/@designanyroom" target="_blank">
                                            <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.6453 9.52284C23.642 8.37713 23.0891 6.90604 23.0892 5.38318H18.5532V23.5859C18.519 24.5712 18.1033 25.5047 17.394 26.1894C16.6846 26.8741 15.7371 27.2565 14.7512 27.2559C12.6667 27.2559 10.9345 25.553 10.9345 23.4391C10.9345 20.9142 13.3713 19.0206 15.8815 19.7986V15.1598C10.817 14.4846 6.38379 18.4187 6.38379 23.4391C6.38379 28.3275 10.4354 31.8065 14.7365 31.8065C19.3459 31.8065 23.0892 28.0632 23.0892 23.4391V14.2056C24.9286 15.5266 27.1369 16.2353 29.4015 16.2314V11.6954C29.4015 11.6954 26.6417 11.8275 24.6453 9.52284Z" fill="white" />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/designanyroom" target="_blank">
                                            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M9.24694 3.52698C5.63828 3.52698 2.71289 6.45237 2.71289 10.061V23.1291C2.71289 26.7378 5.63829 29.6632 9.24694 29.6632H22.315C25.9237 29.6632 28.8491 26.7378 28.8491 23.1291V10.061C28.8491 6.45237 25.9237 3.52698 22.315 3.52698H9.24694ZM23.6219 10.0611C24.3437 10.0611 24.9287 9.47604 24.9287 8.75431C24.9287 8.03258 24.3437 7.4475 23.6219 7.4475C22.9002 7.4475 22.3151 8.03258 22.3151 8.75431C22.3151 9.47604 22.9002 10.0611 23.6219 10.0611ZM22.3151 16.5952C22.3151 20.2038 19.3897 23.1292 15.7811 23.1292C12.1724 23.1292 9.24703 20.2038 9.24703 16.5952C9.24703 12.9865 12.1724 10.0611 15.7811 10.0611C19.3897 10.0611 22.3151 12.9865 22.3151 16.5952ZM15.7811 20.5156C17.9463 20.5156 19.7015 18.7604 19.7015 16.5952C19.7015 14.43 17.9463 12.6747 15.7811 12.6747C13.6159 12.6747 11.8606 14.43 11.8606 16.5952C11.8606 18.7604 13.6159 20.5156 15.7811 20.5156Z" fill="white" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                        </div>
                        <div className=" col-md-2">
                            <h3>{t("Quick Links")}</h3>
                            <ul className="list-unstyled hour-list">
                                <li><a href="/home">{t("Home")}</a></li>
                                <li><a href="/home#Key-Features">{t("Features")}</a></li>
                                <li><a href="/pricing">{t("Pricing")}</a></li>
                                <li><a href="/home#Testimonials">{t("Testimonials")}</a></li>
                                <li><a href="/contact">{t("Contact")}</a></li>
                            </ul>
                        </div>
                        <div className=" col-md-2">
                            <h3>{t("Resources")}</h3>
                            <ul className="list-unstyled hour-list">
                                <li><a href="terms-of-service">{t("Terms of Service")}</a></li>
                                <li><a href="privacy-policy">{t("Privacy Policy")}</a></li>
                                <li><a href="cancellation-policy">{t("Cancellation Policy")}</a></li>
                            </ul>
                        </div>
                        <div className=" col-md-4">
                            <h3>{t("Contact Us")}</h3>
                            <ul className="list-unstyled hour-list">
                                <li><a href="mailto: [contact@designAnyRoom.com]"><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2" y="3.25" width="20" height="18" rx="4" stroke="white" strokeWidth="1.5" />
                                    <path d="M2 7.25L9.50122 13.251C10.9621 14.4197 13.0379 14.4197 14.4988 13.251L22 7.25" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg> contact@designAnyRoom.com</a></li>

                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div className="footer-area dir-rtl">
                <div className="container">
                    <div className="hr-footer"></div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p>{t("Design Any Room")}</p>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer