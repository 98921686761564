import React, { useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import { FilterHistoryObj } from "../../../Models/FilterHistoryObj";

const FilterHistory: React.FunctionComponent<{ filterObj: FilterHistoryObj, onChangeFilter: any }> = (props) => {


	const [style, setStyle] = useState<any>([{ value: "Eclectic", type: "Eclectic" },
	{ value: "Cottage", type: "Cottage" },
	{ value: "Scandinavian", type: "Scandinavian" },
	{ value: "Coastal", type: "Coastal" }, { value: "Traditional", type: "Traditional" },
	{ value: "Bohemian", type: "Bohemian" }, { value: "Art Deco", type: "Art Deco" },
	{ value: "Contemporary", type: "Contemporary" },
	{ value: "Modern", type: "Modern" }, { value: "Midcentury Modern", type: "Midcentury Modern" },
	{ value: "Country", type: "Country-style" }, { value: "Shabby Chic", type: "Shabby Chic" },
	{ value: "Modern Farmhouse", type: "Modern Farmhouse" }, { value: "Americana", type: "Americana" },
	{ value: "French Country", type: "French Country" }, { value: "Transitional", type: "Transitional" },
	{ value: "Hollywood Regency", type: "Hollywood Regency" }, { value: "Chinoiserie", type: "Chinoiserie" },
	{ value: "Mediterranean", type: "Mediterranean" }, { value: "Tropical", type: "Tropical" },
	{ value: "Minimalist", type: "Minimalist" }, { value: "Maximalist", type: "Maximalist" },
	{ value: "Rustic", type: "Rustic" }, { value: "Romantic", type: "Romantic" },
	{ value: "Asian", type: "Asian" }])
	const [styleGarden, setStyleGarden] = useState<any>([{ value: "Japanese Zen Garden", type: "Japanese Zen Garden" },
	{ value: "Mediterranean Garden", type: "Mediterranean Garden" },
	{ value: "Formal French Garden", type: "Formal French Garden" },
	{ value: "Tropical Garden", type: "Tropical Garden" }, { value: "Desert Garden", type: "Desert Garden" },
	{ value: "Modern/Contemporary Garden", type: "Modern/Contemporary Garden" }, { value: "Wildflower Meadow", type: "Wildflower Meadow" },
	{ value: "Cottage Garden", type: "Cottage Garden" },
	{ value: "Rock Garden", type: "Rock Garden" }, { value: "Xeriscape Garden", type: "Xeriscape Garden" },
	{ value: "Native Plant Garden", type: "Native Plant Garden" }, { value: "English Cottage Garden", type: "English Cottage Garden" }])
	const { t } = useTranslation();

	const handleChangeDate = (event: any) => {
		props.onChangeFilter({ date: event.target.value, page: 1 });
	};

	const handleChangeStyle = (event: any) => {
		props.onChangeFilter({ style: event.target.value, page: 1 });
	};

	const handleChangeFeature = (event: any) => {
		props.onChangeFilter({ type: event.target.value, page: 1 });
	};

	return (
		
		<div className="row card-upload marg-filter justify-content-center">

			<div className="col-md-3 mb-2">
				<label className='mb-2'>{t("Select Feature")}</label>
				<FormControl sx={{ m: 1 }} fullWidth>
					<Select
						id="demo-simple-select-helper"
						value={props.filterObj.type}
						inputProps={{ 'aria-label': 'Select Feature' }}
						onChange={handleChangeFeature}
						sx={{
							fontFamily: 'Outfit',
							height: "56px",
							fontSize: "14px",
							lineHeight: "20px",
							color: "#666666",
							border: "1px solid rgba(102, 102, 102, 0.35)",
							borderRadius: "12px",
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: '#2EBB84',
								borderRadius: "12px",
							}
						}}

					>
						<MenuItem value="">{t("None")}</MenuItem>
						<MenuItem value={"Room Staging"}> {t("Room Staging")} </MenuItem>
						<MenuItem value={"Garden Landscaping"}> {t("Garden Landscaping")} </MenuItem>
						<MenuItem value={"2D to 3D Floor"}> {t("2D to 3D Floor Plan")} </MenuItem>
						<MenuItem value={"Style Transfer"}> {t("Style Transfer")} </MenuItem>
					</Select>
				</FormControl>
			</div>

			<div className="col-md-3 mb-2">
				<label className='mb-2'>{t("Select Style")}</label>
				<FormControl sx={{ m: 1 }} fullWidth>
					<Select
						id="demo-simple-select-helper"
						className={`${props.filterObj.type === "Garden Landscaping" || props.filterObj.type === "Room Staging" || props.filterObj.type === "" ? "" : "disabled"}`}
						value={props.filterObj.style}
						inputProps={{ 'aria-label': "Select Style" }}
						onChange={handleChangeStyle}
						sx={{
							fontFamily: 'Outfit',
							height: "56px",
							fontSize: "14px",
							lineHeight: "20px",
							color: "#666666",
							border: "1px solid rgba(102, 102, 102, 0.35)",
							borderRadius: "12px",
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: '#2EBB84',
								borderRadius: "12px",
							}
						}}
					>
						<MenuItem value="">{t("None")}</MenuItem>
						{(props.filterObj.type === "Garden Landscaping" || props.filterObj.type === "") &&
							styleGarden.map((obj: any, index: any) => {
								return <MenuItem key={index} value={obj.value}>{t(`${obj.type}`)} </MenuItem>
							})}
						{(props.filterObj.type === "Room Staging" || props.filterObj.type === "") &&
							style.map((obj: any, index: any) => {
								return <MenuItem key={index} value={obj.value}>{t(`${obj.type}`)} </MenuItem>
							})}
					</Select>
				</FormControl>
			</div>

			<div className="col-md-3 mb-2">
				<label className='mb-2'>{t("Sort by Date")}</label>
				<FormControl sx={{ m: 1 }} fullWidth>
					<Select
						id="demo-simple-select-helper"
						value={props.filterObj.date}
						inputProps={{ 'aria-label': 'Sort by Date' }}
						onChange={handleChangeDate}
						sx={{
							fontFamily: 'Outfit',
							height: "56px",
							fontSize: "14px",
							lineHeight: "20px",
							color: "#666666",
							border: "1px solid rgba(102, 102, 102, 0.35)",
							borderRadius: "12px",
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: '#2EBB84',
								borderRadius: "12px",
							}
						}}
					>
						<MenuItem value="">{t("None")}</MenuItem>
						<MenuItem value="asc">{t("Ascending")}</MenuItem>
						<MenuItem value="desc">{t("Descending")}</MenuItem>
					</Select>
				</FormControl>
			</div>

		</div>
	)

}
export default FilterHistory;