import React, { useState } from "react";
import Pricing from "./pricing";
import Header from "./layout/header";

const PricingPage: React.FunctionComponent<{}> = (props) => {



    return (
        <div className="">
            <Header />

            <Pricing />
        </div>
    )
}
export default PricingPage;