import React from "react";
import Header from "./header";
import { Outlet } from "react-router-dom";
import Footer from "./footer";

const Layout: React.FunctionComponent<{}> = (props) => {

    return (
        <div id="wrapper">
            <main id="main">
                <Outlet />
            </main>
            <Footer />
        </div>
    )
}

export default Layout