import React from 'react';
import { useTranslation } from 'react-i18next';

const DetailsPrincing: React.FunctionComponent<{ }> = (props) => {

    const { t } = useTranslation();

    return (
        <div className='row'>
            <ul className="nav nav-pills" id="TabFeatures" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="free-tab" data-bs-toggle="tab" data-bs-target="#free" type="button" role="tab" aria-controls="free" aria-selected="true">{t("Free")}</button>
                    <div className='line' />
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="basic-tab" data-bs-toggle="tab" data-bs-target="#basic" type="button" role="tab" aria-controls="basic" aria-selected="false">{t("Basic")}</button>
                    <div className='line' />
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="advanced-tab" data-bs-toggle="tab" data-bs-target="#advanced" type="button" role="tab" aria-controls="advanced" aria-selected="false">{t("Advanced")}</button>
                    <div className='line' />
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="professional-tab" data-bs-toggle="tab" data-bs-target="#professional" type="button" role="tab" aria-controls="professional" aria-selected="false">{t("Professional")}</button>
                </li>
            </ul>
            <div className="tab-content" id="TabFeaturesContent">
                <div className="tab-pane fade show active  marg-top-40" id="free" role="tabpanel" aria-labelledby="free-tab">
                    <div className='card '>
                        <div className='title-pricing  text-center'>
                            <h4>{t("Free")}</h4>
                            <p>{t("For users starting out with free")}</p>
                        </div>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-md-4'>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    60 {t("Credits")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("3 Renders & Downloads")}
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Room Staging")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Watermarked")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade marg-top-40 text--start" id="basic" role="tabpanel" aria-labelledby="basic-tab">
                    <div className='card'>
                        <div className='title-pricing  text-center'>
                            <h4>{t("Basic")}</h4>
                            <p>{t("For users who wants to be a little bit more serious")}</p>
                        </div>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-md-4'>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    250 {t("Credits")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Unlimited Renders & Downloads")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Room Staging")}
                                </div>
                            </div>
                            <div className='col-md-4'>

                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("No Watermark")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Garden Landcaping")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Style Transfer")}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("2D to 3D Floor Plan")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Priority Turnaround")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade marg-top-40 text--start" id="advanced" role="tabpanel" aria-labelledby="advanced-tab">
                    <div className='card'>
                        <div className='title-pricing text-center'>
                            <h4 >{t("Advanced")}</h4>
                            <p >{t("For more advances features")}</p>
                        </div>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-md-4'>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    1000 {t("Credits")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Unlimited Renders & Downloads")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Room Staging")}
                                </div>
                            </div>
                            <div className='col-md-4'>

                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("No Watermark")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Garden Landcaping")}
                                </div>

                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Style Transfer")}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("2D to 3D Floor Plan")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Priority Turnaround")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade marg-top-40" id="professional" role="tabpanel" aria-labelledby="professional-tab">
                    <div className='card'>
                        <div className='title-pricing  text-center'>
                            <h4>{t("Professional")}</h4>
                            <p>{t("For professionals and individuals")}</p>
                        </div>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-md-4'>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    3000 {t("Credits")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Unlimited Renders & Downloads")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Room Staging")}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("No Watermark")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Garden Landcaping")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Style Transfer")}
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("2D to 3D Floor Plan")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Priority Turnaround")}
                                </div>
                                <div className="desc">
                                    <span>
                                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                            <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                        </svg>

                                    </span>
                                    {t("Account Manager")}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailsPrincing;