import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Testimonials: React.FunctionComponent<{}> = (props) => {

    const { t, i18n } = useTranslation();

    const [testimonials, setTestimonials] = useState<any>([
        {
            id: 1,
            name: "Maya Hassan",
            role: "Real Estate Agent",
            date: "28/12/2024",
            review: "The 2D to 3D Floor Plan feature is one of",
        }, {
            id: 2,
            name: "James Mitchell",
            role: "Architect",
            date: "30/12/2024",
            review: "AI Room Staging has simplified how I prepare listings for clients",
        }, {
            id: 3,
            name: "Ali AL-Jabari",
            role: "Interior Designer",
            date: "02/01/2025",
            review: "Style Transfer is a lifesaver for designers. It saves so much",
        },
        {
            id: 4,
            name: "Zoe Carter",
            role: "Marketing Specialist",
            date: "01/01/2025",
            review: "Garden Landscaping has been a fantastic addition",
        }
    ])
    const [testimonials1, setTestimonials1] = useState<any>([
        {
            id: 5,
            name: "Elena Petrova",
            role: "Real Estate Agent",
            date: "20/10/2024",
            review: "DesignAnyRoom has made my job so much easier",
        },
        {
            id: 6,
            name: "Rami Kassem",
            role: "Real Estate Manager",
            date: "11/12/2024",
            review: "The AI Room Staging tool has completely transformed how",
        },
        {
            id: 7,
            name: "Nadine",
            role: "Interior Architect",
            date: "04/09/2024",
            review: "Style Transfer makes my job so much easier. It allows me to test",
        },
        {
            id: 8,
            name: "Olivier Bennet",
            role: "Executive Director",
            date: "30/10/2024",
            review: "The 2D to 3D Floor Plan feature is incredibly efficient",
        }
    ])
    
    
    return (
        <div className="work-sec service-community " id="Testimonials">
            <h2 className="sec-9-title text-center mb-3 mt-4">{t("Testimonials")}</h2>
            <div className="testimonials-container ">
                <div className="testimonials-wrapper">
                    {testimonials.map((testimonial: any) => (
                        <div className="testimonial-card dir-rtl" key={testimonial.id}>
                            <div className="testimonial-header mb-2">
                                <svg width="136" height="25" viewBox="0 0 136 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="25.3125" height="24.4849" transform="translate(0.5 0.285156)" fill="#29BD81" />
                                    <path d="M16.8315 15.811L18.3713 20.5515L13.1591 16.763L16.8315 15.811ZM21.5938 10.636H15.1496L13.1598 4.50403L11.1629 10.6374L4.71875 10.629L9.93734 14.4237L7.94047 20.5507L13.1591 16.763L16.3815 14.4237L21.5938 10.636Z" fill="white" />
                                    <rect width="25.3125" height="24.4849" transform="translate(27.9219 0.285278)" fill="#29BD81" />
                                    <path d="M44.2534 15.8111L45.7932 20.5516L40.5809 16.7631L44.2534 15.8111ZM49.0156 10.6361H42.5715L40.5816 4.50415L38.5848 10.6375L32.1406 10.6291L37.3592 14.4238L35.3623 20.5509L40.5809 16.7631L43.8034 14.4238L49.0156 10.6361Z" fill="white" />
                                    <rect width="25.3125" height="24.4849" transform="translate(55.3438 0.285278)" fill="#29BD81" />
                                    <path d="M71.6752 15.8111L73.2151 20.5516L68.0028 16.7631L71.6752 15.8111ZM76.4375 10.6361H69.9934L68.0035 4.50415L66.0066 10.6375L59.5625 10.6291L64.7811 14.4238L62.7842 20.5509L68.0028 16.7631L71.2252 14.4238L76.4375 10.6361Z" fill="white" />
                                    <rect width="25.3125" height="24.4849" transform="translate(82.7656 0.285278)" fill="#29BD81" />
                                    <path d="M99.0971 15.8111L100.637 20.5516L95.4247 16.7631L99.0971 15.8111ZM103.859 10.6361H97.4152L95.4254 4.50415L93.4285 10.6375L86.9844 10.6291L92.203 14.4238L90.2061 20.5509L95.4247 16.7631L98.6471 14.4238L103.859 10.6361Z" fill="white" />
                                    <rect width="25.3125" height="24.4849" transform="translate(110.188 0.285278)" fill="#29BD81" />
                                    <path d="M126.519 15.8111L128.059 20.5516L122.847 16.7631L126.519 15.8111ZM131.281 10.6361H124.837L122.847 4.50415L120.85 10.6375L114.406 10.6291L119.625 14.4238L117.628 20.5509L122.847 16.7631L126.069 14.4238L131.281 10.6361Z" fill="white" />
                                </svg>
                                <p className="testimonial-date">{testimonial.date}</p>
                            </div>
                            <div className="testimonial-header mb-2">
                                <p className='testimonial-name'>{t(`${testimonial.name}`)}</p>
                            </div>
                            <p className="testimonial-role mb-2">{t(`${testimonial.role}`)}</p>
                            <div className='testimonial-line' />
                            <p className="testimonial-review mt-2">{t(`${testimonial.review}`)}</p>
                        </div>
                    ))}
                </div>

                <div className="testimonials-wrapper">
                    {testimonials1.map((testimonial: any) => (
                        <div className="testimonial-card testimonial-first dir-rtl" key={testimonial.id}>
                            <div className="testimonial-header mb-2">
                                <svg width="136" height="25" viewBox="0 0 136 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="25.3125" height="24.4849" transform="translate(0.5 0.285156)" fill="#29BD81" />
                                    <path d="M16.8315 15.811L18.3713 20.5515L13.1591 16.763L16.8315 15.811ZM21.5938 10.636H15.1496L13.1598 4.50403L11.1629 10.6374L4.71875 10.629L9.93734 14.4237L7.94047 20.5507L13.1591 16.763L16.3815 14.4237L21.5938 10.636Z" fill="white" />
                                    <rect width="25.3125" height="24.4849" transform="translate(27.9219 0.285278)" fill="#29BD81" />
                                    <path d="M44.2534 15.8111L45.7932 20.5516L40.5809 16.7631L44.2534 15.8111ZM49.0156 10.6361H42.5715L40.5816 4.50415L38.5848 10.6375L32.1406 10.6291L37.3592 14.4238L35.3623 20.5509L40.5809 16.7631L43.8034 14.4238L49.0156 10.6361Z" fill="white" />
                                    <rect width="25.3125" height="24.4849" transform="translate(55.3438 0.285278)" fill="#29BD81" />
                                    <path d="M71.6752 15.8111L73.2151 20.5516L68.0028 16.7631L71.6752 15.8111ZM76.4375 10.6361H69.9934L68.0035 4.50415L66.0066 10.6375L59.5625 10.6291L64.7811 14.4238L62.7842 20.5509L68.0028 16.7631L71.2252 14.4238L76.4375 10.6361Z" fill="white" />
                                    <rect width="25.3125" height="24.4849" transform="translate(82.7656 0.285278)" fill="#29BD81" />
                                    <path d="M99.0971 15.8111L100.637 20.5516L95.4247 16.7631L99.0971 15.8111ZM103.859 10.6361H97.4152L95.4254 4.50415L93.4285 10.6375L86.9844 10.6291L92.203 14.4238L90.2061 20.5509L95.4247 16.7631L98.6471 14.4238L103.859 10.6361Z" fill="white" />
                                    <rect width="25.3125" height="24.4849" transform="translate(110.188 0.285278)" fill="#29BD81" />
                                    <path d="M126.519 15.8111L128.059 20.5516L122.847 16.7631L126.519 15.8111ZM131.281 10.6361H124.837L122.847 4.50415L120.85 10.6375L114.406 10.6291L119.625 14.4238L117.628 20.5509L122.847 16.7631L126.069 14.4238L131.281 10.6361Z" fill="white" />
                                </svg>
                                <p className="testimonial-date">{testimonial.date}</p>
                            </div>
                            <div className="testimonial-header mb-2">
                            <p className='testimonial-name'>{t(`${testimonial.name}`)}</p>
                            </div>
                            <p className="testimonial-role mb-2">{t(`${testimonial.role}`)}</p>
                            <div className='testimonial-line' />
                            <p className="testimonial-review mt-2">{t(`${testimonial.review}`)}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Testimonials;