import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetAccount, isLogged } from "../../Services/Authentication";
import Subscription from "./subscription";

const Pricing: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();
    const [account, setAccount] = useState<any>("");

    useEffect(() => {
        if (isLogged()) {
            GetAccount().then((resp) => {
                setAccount(resp)
            }).catch((error) => {
                console.log("error")
            })
        }
    }, []);

    return (

        <section className="work-sec " id="Pricing">
            <div className="row dir-rtl">
                <div className="col-md-12 text-center header ">
                    <h2 className="sec-9-title mb-3 mt-4">{t("Choose the plan that fits")}</h2>
                </div>
            </div>
            <div className="padd-50 mt-4">
                <Subscription User={account !== "" ? account : ""} />
            </div>
        </section>

    )
}
export default Pricing;