import React, { useEffect, useState } from "react";
import { DownloadEnhancedImage, GetHistory } from "../../../Services/Generate";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { GetAccount } from "../../../Services/Authentication";
import Head from "../layout/head";
import { useTranslation } from "react-i18next";
import { AccountObj } from "../../../Models/Account";
import FilterHistory from "./filter-history";
import Pagination from '@mui/material/Pagination';
import { FilterHistoryObj } from "../../../Models/FilterHistoryObj";


const History: React.FunctionComponent<{}> = (props) => {

	const [history, setHistory] = useState<any>();
	const [loading, setLoading] = useState(true);
	const [isSpinnerDown, setIsSpinnerDown] = useState<Record<number, boolean>>({});;
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [account, setAccount] = useState<AccountObj>({});
	const { t } = useTranslation();
	const [filterObj, setFilterObj] = useState<FilterHistoryObj>({
		style: "",
		date: "",
		type: "",
		page: 1,
		perPage: 18,
	})
	const [total, setTotal] = useState<number>();
	

	useEffect(() => {
		GetHistory(filterObj).then((data) => {
			console.log(data)
			setLoading(false)
			setHistory(data.data)
			setTotal(Math.ceil(data.total / 18))
		}).catch((error: any) => {
			console.log("error")
		})
	}, [filterObj])

	useEffect(() => {
		GetAccount().then((resp) => {
			setLoading(false)
			setAccount(resp)
		})
	}, [])

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleChangePagination = (event: any, value: any) => {
		setFilterObj((prevData: any) => ({
			...prevData,
			page: value,
		}));
	};

	const ChangeFilterHandler = (data :any) => {
		setFilterObj((prev) => ({
		  ...prev,
		  ...data,
		}));
	  };

	const handleDownload = async (url: any, index: any) => {
		setIsSpinnerDown((prev: any) => ({ ...prev, [index]: true }))
		const imageName = 'image.jpg';
		const urlImg = `https://www.api-v1.coralytics.com` + url
		try {
			const response = await fetch(urlImg);
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = imageName;
			a.click();
			setIsSpinnerDown((prev: any) => ({ ...prev, [index]: false }))
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error('Error downloading image:', error);
		}
	};

	const handleDownloadEnhance = (url: any, id: any) => {
		const image = { "image": "https://www.api-v1.coralytics.com" + url, "id": id }
		const imageName = 'image-enhanced.jpg';
		setIsSpinnerDown((prev: any) => ({ ...prev, [id]: true }))
		DownloadEnhancedImage(image).then(async (resp) => {
			try {
				const response = await fetch(resp.src);
				const blob = await response.blob();
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = imageName;
				a.click();
				window.URL.revokeObjectURL(url);
				setIsSpinnerDown((prev: any) => ({ ...prev, [id]: false }))
			} catch (error) {
				console.error('Error downloading image:', error);
			}
		})

	};

	return loading ? (<div className="content-wrapper"> {t("Loading ...")}</div>) : (
		<div className="page-dash">
			<Head title={"History"} User={account && account ? account : ""} />

			<FilterHistory filterObj={filterObj} onChangeFilter={ChangeFilterHandler}/>

			<div className="row m-50">
				{history && history.map((history_obj: any, index: number) => {
					return <>
						{history_obj.list_images.map((image: any, index_: number) => {
							return <div className="col-md-4 mb-3" key={index + index_}>
								<div className="card-dash">
									<div className="image-history">
										<ReactCompareSlider
											itemOne={<ReactCompareSliderImage src={image.img_before && image.img_before.src} key={"after_" + index} className="img-slide" alt="Image Before" />}
											itemTwo={<ReactCompareSliderImage src={image.img_after && `https://www.api-v1.coralytics.com` + image.img_after.src} className="img-slide" key={"before_" + index} alt="Image After" />}
										/>
									</div>
									<div className="desc-history padd-10">
										<div className="colv-1">
											<p className="title">{t("Task")}</p>
											<p className="title">{t("Date")}</p>
										</div>
										<div className="colv-1">
											<p className="desc">{image.type ? `${t(image.type)}` : ""}</p>
											<p className="desc">{history_obj.date ? history_obj.date : ""}</p>
										</div>
									</div>
									<div className="desc-history padd-10-1 justify-content-center">
										<div className="dropdown-1">
											<button className="btn-download" onClick={toggleDropdown}>
												{isSpinnerDown[image._id] ? (
													<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
														<Box sx={{ display: "flex" }}>
															<CircularProgress size={30} sx={{ color: "#29BD81" }} />
														</Box>
													</div>
												) : (
													`${t("Download")}`
												)}
											</button>
											{isDropdownOpen && (
												<div className="dropdown-content-1">
													<a style={{ textAlign: "center" }} onClick={() => handleDownload(image.img_after.src, image._id)}>
														{t("Original")}
													</a>
													{account.subscription !== "free" && <a style={{ textAlign: "center" }} onClick={() => handleDownloadEnhance(image.img_after.src, image._id)}>
														<Tooltip title={<h1 style={{ fontSize: "14px", color: "#FFF", padding: "10px" }}>{t("Selecting this option will use up 20 credits")}</h1>}>
															<IconButton>
																<i className="fa-solid fa-circle-info"></i>
															</IconButton>
														</Tooltip>
														{t("High Quality")}
													</a>}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						})}
					</>
				})
				}
			</div>

			{history && history.length === 0 ?
				<div className="card-dash mb-3">
					<div className="card-body-dash padd-20">
						<h2 className="msg-empty"> {t("No History")}</h2>
					</div>
				</div>
				:
				<div className="custom-pagination d-flex justify-content-center">
					<Pagination
						count={total}
						page={filterObj.page}
						variant="outlined"
						color="secondary"
						size="large"
						onChange={handleChangePagination}
					/>
				</div>
			}
		</div>
	)

}
export default History;