import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { useNavigate } from 'react-router-dom';
import { isLogged } from '../../Services/Authentication';

const FeaturesDetails: React.FunctionComponent<{}> = (props) => {

      const { t, i18n } = useTranslation();
      const navigate = useNavigate();

      const getImagePath = (fileName: any) => {
            return `/assets/images-v2/${i18n.language}/${fileName}`;
      };

      const HandleClick = () => {
            if (isLogged()) {
                  navigate('/Dashboard')
            } else {
                  navigate('/pricing')
            }
      }

      return (
            <>
                  {/* AI Room Staging */}

                  <div className="work-sec container dir-rtl" id="Room-Staging">
                        <div className="row d-flex align-items-center">
                              <div className="col-md-6 order-11">
                                    <div className="img-holder ">
                                          <img src="/assets/images-v2/ai-room-staging.webp" className='img-fluid' alt="re-design" />
                                    </div>
                              </div>
                              <div className='col-md-6 order-00'>
                                    <span className="button-title">{t("AI Room Staging")}</span>
                                    <h2 className="sec-9-title mb-3 mt-4">{t("Reimagine Your Interiors")}</h2>
                                    <p className='para-20 mb-3'>{t("Transform empty or outdated rooms")}</p>
                                    <div className="feature-item mt-4">
                                          <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                          </svg>
                                          <div className="features-tags text--start">
                                                <h3 className='marg-0'>{t("Effortless Redecoration")}</h3>
                                                <p className='marg-0'>{t("No need for physical furniture")}</p>
                                          </div>
                                    </div>
                                    <div className="feature-item">
                                          <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                          </svg>
                                          <div className="features-tags text--start">
                                                <h3 className='marg-0'>{t("Cost-Effective")}</h3>
                                                <p className='marg-0'>{t("Save on interior design")}</p>
                                          </div>
                                    </div>
                                    <div className="feature-item">
                                          <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                          </svg>
                                          <div className="features-tags text--start">
                                                <h3 className='marg-0'>{t("Instant Results")}</h3>
                                                <p className='marg-0'>{t("Save on interior")}</p>
                                          </div>
                                    </div>
                                    <div className="btn-holder">
                                          <button className="button-login height-button mt-4" onClick={HandleClick}>{t("Try For Free")}</button>
                                    </div>
                              </div>
                        </div>

                  </div>

                  {/* Graden and Style Transfert and 2D to 3D and Modify your Facade */}

                  <div className="work-sec container  dir-rtl text-center">
                        <div className='row  d-flex align-items-center ' id="Features">
                              <ul className="nav nav-pills" id="TabFeatures" role="tablist">
                                    <li className="nav-item" role="presentation">
                                          <button className="nav-link active" id="transfer-tab" data-bs-toggle="tab" data-bs-target="#transfer" type="button" role="tab" aria-controls="transfer" aria-selected="false">{t("Style Transfer")}</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                          <button className="nav-link" id="floor-tab" data-bs-toggle="tab" data-bs-target="#floor" type="button" role="tab" aria-controls="floor" aria-selected="false">{t("2D to 3D Floor Plan")}</button>
                                          <div className='line' />
                                    </li>
                                    {/* <li className="nav-item" role="presentation">
                                          <button className="nav-link" id="facade-tab" data-bs-toggle="tab" data-bs-target="#facade" type="button" role="tab" aria-controls="facade" aria-selected="false">{t("Modify your facade")}</button>
                                          <div className='line' />
                                    </li> */}

                                    <li className="nav-item" role="presentation">
                                          <button className="nav-link " id="garden-tab" data-bs-toggle="tab" data-bs-target="#garden" type="button" role="tab" aria-controls="garden" aria-selected="true">{t("Garden Landscaping")}</button>
                                          <div className='line' />
                                    </li>
                              </ul>
                              <div className="tab-content" id="TabFeaturesContent">
                                    <div className="tab-pane fade show active marg-top-80" id="transfer" role="tabpanel" aria-labelledby="transfer-tab">
                                          <h2 className="sec-9-title mb-3 mt-4">{t("Floor Plan & Style Transfer")}</h2>
                                          <p className='para-20 mb-3 marg-size-center'>{t("Upload any style inspiration image")}</p>

                                          <div className="btn-holder">
                                                <button className="button-login height-button  mt-2 mb-4" onClick={HandleClick}>{t("Try For Free")}</button>
                                          </div>

                                          <div className="img-holder mt-4">
                                                <img src={getImagePath("Mask group.webp")} className='img-fluid' alt="re-design" />
                                          </div>

                                          <div className="list-horiz mt-4">
                                                <div className="feature-item">
                                                      <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                            <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                            <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                      </svg>
                                                      <div className="features-tags text--start">
                                                            <h3 className='marg-0'>{t("Upload Style ")}</h3>
                                                            <p className='marg-0'>{t("Upload a photo of your preferred style")}</p>
                                                      </div>
                                                </div>
                                                <div className="feature-item">
                                                      <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                            <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                            <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                      </svg>
                                                      <div className="features-tags text--start">
                                                            <h3 className='marg-0'>{t("Apply Style")}</h3>
                                                            <p className='marg-0'>{t("Apply the uploaded style to your room")}</p>
                                                      </div>
                                                </div>
                                                <div className="feature-item">
                                                      <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                            <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                            <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                      </svg>
                                                      <div className="features-tags text--start">
                                                            <h3 className='marg-0'>{t("Easy to Use")}</h3>
                                                            <p className='marg-0'>{t("Simple upload and apply")}</p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="tab-pane fade marg-top-80 text--start" id="floor" role="tabpanel" aria-labelledby="floor-tab">
                                          <div className="row d-flex align-items-center">
                                                <div className="col-md-6 order-11">
                                                      <div className="img-holder ">
                                                            <ReactCompareSlider
                                                                  itemOne={<ReactCompareSliderImage src="/assets/images-v2/image-before.webp" alt="Image one" />}
                                                                  itemTwo={<ReactCompareSliderImage src="/assets/images-v2/image-after.webp" alt="Image two" />}
                                                            />
                                                      </div>
                                                </div>
                                                <div className='col-md-6 order-00 dir-rtl'>
                                                      <span className="button-title ">{t("2D to 3D Floor Plan")}</span>
                                                      <h2 className="sec-9-title mb-3 mt-4">{t("Experience Your Space in 3D")}</h2>
                                                      <p className='para-20 mb-3'>{t("Bring flat floor plans to life")}</p>
                                                      <div className="feature-item mt-4">
                                                            <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                                  <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                                  <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                            </svg>                                                            <div className="features-tags text--start">
                                                                  <h3 className='marg-0'>{t("Immersive Visualization")}</h3>
                                                                  <p className='marg-0'>{t("Understand space dimensions")}</p>
                                                            </div>
                                                      </div>
                                                      <div className="feature-item">
                                                            <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                                  <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                                  <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                            </svg>                                                            <div className="features-tags text--start">
                                                                  <h3 className='marg-0'>{t("Improved Planning")}</h3>
                                                                  <p className='marg-0'>{t("Optimize layouts before")}</p>
                                                            </div>
                                                      </div>
                                                      <div className="feature-item">
                                                            <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                                  <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                                  <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                            </svg>                                                            <div className="features-tags text--start">
                                                                  <h3 className='marg-0'>{t("Shareable Models")}</h3>
                                                                  <p className='marg-0'>{t("Easily present to others")}</p>
                                                            </div>
                                                      </div>
                                                      <div className="btn-holder">
                                                            <button className="button-login height-button mt-4" onClick={HandleClick}>{t("Try For Free")}</button>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="tab-pane fade marg-top-80 text--start" id="facade" role="tabpanel" aria-labelledby="facade-tab">
                                          <div className="row d-flex align-items-center">
                                                <div className="col-md-7 order-11">
                                                      <div className="img-holder ">
                                                            <img src="/assets/images-v2/Group 3.webp" className='img-fluid' alt="re-design" />
                                                      </div>
                                                </div>
                                                <div className='col-md-5 order-00'>
                                                      <h2 className="sec-9-title mb-3 mt-4">{t("Give Your Home a Stunning")}</h2>
                                                      <p className='para-20 mb-3'>{t("Revamp your home's exterior")}</p>
                                                      <div className="feature-item mt-3">
                                                            <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                                  <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                                  <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                            </svg>
                                                            <div className="features-tags text--start">
                                                                  <h3 className='marg-0'>{t("Explore Styles")}</h3>
                                                                  <p className='marg-0'>{t("From modern to traditional.")}</p>
                                                            </div>
                                                      </div>
                                                      <div className="feature-item">
                                                            <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                                  <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                                  <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                            </svg>
                                                            <div className="features-tags text--start">
                                                                  <h3 className='marg-0'>{t("Boost Curb Appeal")}</h3>
                                                                  <p className='marg-0'>{t("Make a lasting impression.")}</p>
                                                            </div>
                                                      </div>
                                                      <div className="feature-item">
                                                            <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                  <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                                  <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                                  <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                            </svg>
                                                            <div className="features-tags text--start">
                                                                  <h3 className='marg-0'>{t("Plan Renovations")}</h3>
                                                                  <p className='marg-0'>{t("See results before investing.")}</p>
                                                            </div>
                                                      </div>
                                                      <div className="btn-holder">
                                                            <button className="button-login height-button mt-4" onClick={HandleClick}>{t("Try For Free")}</button>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="tab-pane fade   marg-top-80" id="garden" role="tabpanel" aria-labelledby="garden-tab">
                                          <h2 className="sec-9-title mb-3">{t("Design Your Perfect")}</h2>
                                          <p className='para-20 mb-3 marg-size-center'>
                                                {t("Elevate your outdoor spaces").split("\n").map((item, index) => (
                                                      <React.Fragment key={index}>
                                                            {item}
                                                            <br />
                                                      </React.Fragment>
                                                ))}</p>

                                          <div className="btn-holder">
                                                <button className="button-login height-button  mt-2 mb-4" onClick={HandleClick}>{t("Try For Free")}</button>
                                          </div>

                                          <div className="img-holder mt-4">
                                                <img src={getImagePath("garden-img.webp")} className='img-fluid' alt="re-design" />
                                          </div>
                                          <div className="list-horiz mt-4">
                                                <div className="feature-item">
                                                      <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                            <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                            <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                      </svg>
                                                      <div className="features-tags text--start">
                                                            <h3 className='marg-0'>{t("Personalized Designs")}</h3>
                                                            <p className='marg-0'>{t("Tailor your garden to your")}</p>
                                                      </div>
                                                </div>
                                                <div className="feature-item">
                                                      <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                            <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                            <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                      </svg>
                                                      <div className="features-tags text--start">
                                                            <h3 className='marg-0'>{t("Save Time and Money")}</h3>
                                                            <p className='marg-0'>{t("Plan before planting.")}</p>
                                                      </div>
                                                </div>
                                                <div className="feature-item">
                                                      <svg width="37" height="38" className='marg-right-10' viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18.229" cy="18.9766" r="15.8765" fill="#29BD81" />
                                                            <circle cx="18.2286" cy="18.9766" r="17.891" stroke="#29BDB3" strokeWidth="0.675132" />
                                                            <path d="M15.1133 22.8982L24.8726 13.1389C25.1029 12.9086 25.3716 12.7935 25.6786 12.7935C25.9857 12.7935 26.2544 12.9086 26.4847 13.1389C26.715 13.3692 26.8302 13.6429 26.8302 13.96C26.8302 14.277 26.715 14.5503 26.4847 14.7799L15.9194 25.374C15.6891 25.6043 15.4204 25.7194 15.1133 25.7194C14.8062 25.7194 14.5375 25.6043 14.3072 25.374L9.35561 20.4224C9.1253 20.1921 9.01476 19.9188 9.02397 19.6025C9.03318 19.2862 9.15332 19.0125 9.3844 18.7814C9.61547 18.5504 9.88916 18.4352 10.2054 18.436C10.5217 18.4368 10.795 18.5519 11.0253 18.7814L15.1133 22.8982Z" fill="white" />
                                                      </svg>
                                                      <div className="features-tags text--start">
                                                            <h3 className='marg-0'>{t("Enhance Property Value")}</h3>
                                                            <p className='marg-0'>{t("Boost curb appeal")}</p>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>

                  {/* Expert Designers */}

                  <div className="work-sec container dir-rtl">
                        <div className="row d-flex align-items-center">

                              <div className='col-md-5  order-00'>
                                    <div className="txt-holder ">
                                          <span className="button-title">{t("Expert Designers")}</span>
                                          <h2 className="sec-9-title mb-3 mt-4">{t("Need a Professional")}</h2>
                                          <p className='para-20 mb-3'>{t("DesignAnyRoom connects you with trusted")}</p>
                                          <div className="btn-holder">
                                                <button className="button-login height-button mt-4" onClick={HandleClick}>{t("Try For Free")}</button>
                                          </div>
                                    </div>
                              </div>
                              <div className="col-md-7  order-11">
                                    <div className='img-holder'>
                                          <img src="/assets/images-v2/Expert Designers.webp" className='img-fluid' alt="re-design" />
                                    </div>
                              </div>
                        </div>
                  </div>

            </>
      )
}
export default FeaturesDetails;
