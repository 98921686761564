import React from 'react';
import { useTranslation } from "react-i18next";
import Header from './layout/header';

const CancellationPolicy: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();


    return (
        <>
            <Header />

            <div className=" dir-rtl terms">
                <div className="container">
                    <div className="text-center">
                        <h1 className='h1-title'>{t("Cancellation Policy")}</h1>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <h2 className="title mt-4">{t("Effective Date:")} 21/11/2024</h2>
                            <p className='desc'>{t("At DesignAnyRoom, we value your business and aim")}</p>
                            <h2 className="title mt-4">1. {t("Cancellation Process")}</h2>
                            <p className='desc'>1.1 {t("To cancel your subscription")} <a href="mailto:operations@coralytics.com">operations@coralytics.com </a> {t("with the subject line")}</p>
                            <p className='desc'>1.2 {t("The email must include")}</p>
                            <ul>
                                <li><p className='desc'>{t("Your account details")}</p></li>
                                <li><p className='desc'>{t("The reason for cancellation")}</p></li>
                            </ul>
                            <h2 className="title mt-4">2. {t("Notice Period")}</h2>
                            <p className='desc'> 2.1 {t("Cancellation requests must be submitted at least")}</p>
                            <h2 className="title mt-4">3. {t("Confirmation of Cancellation")}</h2>
                            <p className='desc'> 3.1 {t("Once we receive your email, we will")}</p>
                            <h2 className="title mt-4">4. {t("Effect of Cancellation")}</h2>
                            <p className='desc'>4.1 {t("Upon cancellation, your subscription")}</p>
                            <p className='desc'>4.2 {t("No refunds will be issued for the unused")}</p>
                            <h2 className="title mt-4">5. {t("Contact Information")}</h2>
                            <p className='desc'>{t("If you have any questions regarding")} <a href="mailto:contact@designAnyRoom.com"> contact@designAnyRoom.com </a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CancellationPolicy;
