import React, { useEffect, useState } from 'react'
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetAccount, isLogged } from '../../../Services/Authentication';
import { useTranslation } from 'react-i18next';

const HeaderHome: React.FunctionComponent<{}> = () => {

    const { t, i18n } = useTranslation();
    const [langue, setLangue] = useState<any>(localStorage.getItem('language') ? localStorage.getItem('language') : "en");
    let location = useLocation().pathname.split('/');
    const pathname = location[1];
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [user, setUser] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLogged()) {
            GetAccount().then((resp) => {
                setUser(resp)
            }).catch((error) => {
                console.log("error")
            })
        }
    }, []);

    const SignUp = () => {
        navigate("/sign-up")
        $("body").toggleClass("nav-active");
        localStorage.removeItem('subscription');
    }

    const SignIn = () => {
        navigate("/login")
        $("body").toggleClass("nav-active");
    }
    
    const toggleDropdown = () => {
        setIsDropdownOpen(true);
    };

    const changeLanguage = (lng: any) => {
        setIsDropdownOpen(false)
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng);
        setLangue(lng)
        document.body.classList.toggle('rtl', lng === 'ar');
        document.body.classList.toggle('ltr', lng !== 'ar');
        $("body").toggleClass("nav-active");

    };

    const getSidebar = (event: any) => {
        event.preventDefault();
        $("body").toggleClass("nav-active");
    }

    return (
        <header id="header">
            <div className="header-holder dir-rtl">
                <div className="row" >
                    <div className="col-md-12">
                        <div className='container'>
                            <nav id="nav">
                                <div className="logo">
                                    <a href="/home">
                                        <img src="/assets/images-v2/logo-design-white.svg" width="221" height="66" className="img-responsive" alt="logo" />
                                    </a>
                                </div>
                                <div className='nav-opener'>
                                    <a onClick={getSidebar} className=" text-center md-round"><svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1H37" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M1 9H37" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M1 17H37" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M1 25H37" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                    </svg>

                                    </a>
                                </div>
                                <ul className="list-unstyled back-nav gap-34">
                                    <li className={`${pathname == "home" || pathname == "" ? "active" : ""}`}>
                                        <a href="/home">
                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.5625 5.43734L6.25 1.7915L10.9375 5.43734V11.1665C10.9375 11.4428 10.8278 11.7077 10.6324 11.9031C10.4371 12.0984 10.1721 12.2082 9.89583 12.2082H2.60417C2.3279 12.2082 2.06295 12.0984 1.8676 11.9031C1.67225 11.7077 1.5625 11.4428 1.5625 11.1665V5.43734Z" stroke="white" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M4.6875 12.2083V7H7.8125V12.2083" stroke="white" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            {t("HOME")}</a>
                                    </li>
                                    <li className={`${pathname == "pricing" ? "active" : ""}`}><a href="/pricing"><svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_14_72)">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.74984 0.75C7.03749 0.75 7.27067 0.983185 7.27067 1.27083V2.83333H9.354C9.64165 2.83333 9.87484 3.06652 9.87484 3.35417C9.87484 3.64181 9.64165 3.875 9.354 3.875H7.27067V6.47917H8.05192C8.67352 6.47917 9.26966 6.7261 9.7092 7.16564C10.1487 7.60517 10.3957 8.20132 10.3957 8.82292C10.3957 9.44452 10.1487 10.0407 9.7092 10.4802C9.26966 10.9197 8.67352 11.1667 8.05192 11.1667H7.27067V12.7292C7.27067 13.0168 7.03749 13.25 6.74984 13.25C6.46219 13.25 6.229 13.0168 6.229 12.7292V11.1667H3.62484C3.33719 11.1667 3.104 10.9335 3.104 10.6458C3.104 10.3582 3.33719 10.125 3.62484 10.125H6.229V7.52083H5.44775C4.82615 7.52083 4.23001 7.2739 3.79047 6.83436C3.35093 6.39483 3.104 5.79868 3.104 5.17708C3.104 4.55548 3.35093 3.95934 3.79047 3.5198C4.23001 3.08026 4.82615 2.83333 5.44775 2.83333H6.229V1.27083C6.229 0.983185 6.46219 0.75 6.74984 0.75ZM6.229 3.875H5.44775C5.10242 3.875 4.77123 4.01218 4.52704 4.25637C4.28285 4.50056 4.14567 4.83175 4.14567 5.17708C4.14567 5.52242 4.28285 5.85361 4.52704 6.0978C4.77123 6.34198 5.10242 6.47917 5.44775 6.47917H6.229V3.875ZM7.27067 7.52083V10.125H8.05192C8.39725 10.125 8.72845 9.98782 8.97263 9.74363C9.21682 9.49944 9.354 9.16825 9.354 8.82292C9.354 8.47758 9.21682 8.14639 8.97263 7.9022C8.72845 7.65802 8.39725 7.52083 8.05192 7.52083H7.27067Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_14_72">
                                                <rect width="12.5" height="12.5" fill="white" transform="translate(0.5 0.75)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                        {t("PRICING")}</a></li>
                                    <li className={`${pathname == "contact" ? "active" : ""}`}>
                                        <a href="/contact"><svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9375 6.7396C10.9393 7.42703 10.7787 8.10516 10.4687 8.71876C10.1013 9.45405 9.53633 10.0725 8.83721 10.5048C8.1381 10.9372 7.33242 11.1664 6.51042 11.1667C5.82299 11.1685 5.14485 11.0079 4.53125 10.6979L1.5625 11.6875L2.55208 8.71876C2.24215 8.10516 2.08154 7.42703 2.08333 6.7396C2.08365 5.9176 2.31282 5.11192 2.74517 4.4128C3.17751 3.71369 3.79597 3.14875 4.53125 2.78126C5.14485 2.47133 5.82299 2.31072 6.51042 2.31251H6.77083C7.85643 2.37241 8.88179 2.83062 9.65059 3.59942C10.4194 4.36822 10.8776 5.39358 10.9375 6.47918V6.7396Z" stroke="white" strokeWidth="1.08333" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                            {t("CONTACT US")}</a></li>
                                </ul>
                                <ul className="list-unstyled gap-18">
                                    <li>
                                        {!isLogged() ? <a className="button-login height-36" href="login">{t("Login")}
                                        </a> :
                                            <a href="Dashboard">
                                                <img src={user && user.profile ? user.profile : "/assets/images-v2/user-profile.webp"} className="nav-profile" alt="profile" /></a>}
                                    </li>
                                    <li>
                                        {!isLogged() && <button className="button-signup button-signup-flex" onClick={SignUp}>{t("Sign Up")}
                                        </button>}
                                    </li>
                                    <li>
                                        <div className="dropdown">
                                            <button className="drop-lang-us dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" onClick={toggleDropdown}
                                                aria-expanded={isDropdownOpen}>
                                                {langue.toUpperCase()}
                                                <i className="fa-solid fa-chevron-down"></i>
                                            </button>
                                            <ul className={`dropdown-menu width-drop ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1">
                                                <li><a className="dropdown-item" style={{ margin: "0px" }} onClick={() => changeLanguage('en')}>EN</a></li>
                                                <li><a className="dropdown-item" style={{ margin: "0px" }} onClick={() => changeLanguage('ar')}>AR</a></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div className='nav-mobile dir-rtl' id="#nav">
                <ul className="list-unstyled ">
                    <li className={`${pathname == "home" || pathname == "" ? "active" : ""}`}>
                        <a href="/home">
                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5625 5.43734L6.25 1.7915L10.9375 5.43734V11.1665C10.9375 11.4428 10.8278 11.7077 10.6324 11.9031C10.4371 12.0984 10.1721 12.2082 9.89583 12.2082H2.60417C2.3279 12.2082 2.06295 12.0984 1.8676 11.9031C1.67225 11.7077 1.5625 11.4428 1.5625 11.1665V5.43734Z" stroke="white" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.6875 12.2083V7H7.8125V12.2083" stroke="white" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {t("HOME")}</a>
                    </li>
                    <li className={`${pathname == "pricing" ? "active" : ""}`}><a href="/pricing"><svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_14_72)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.74984 0.75C7.03749 0.75 7.27067 0.983185 7.27067 1.27083V2.83333H9.354C9.64165 2.83333 9.87484 3.06652 9.87484 3.35417C9.87484 3.64181 9.64165 3.875 9.354 3.875H7.27067V6.47917H8.05192C8.67352 6.47917 9.26966 6.7261 9.7092 7.16564C10.1487 7.60517 10.3957 8.20132 10.3957 8.82292C10.3957 9.44452 10.1487 10.0407 9.7092 10.4802C9.26966 10.9197 8.67352 11.1667 8.05192 11.1667H7.27067V12.7292C7.27067 13.0168 7.03749 13.25 6.74984 13.25C6.46219 13.25 6.229 13.0168 6.229 12.7292V11.1667H3.62484C3.33719 11.1667 3.104 10.9335 3.104 10.6458C3.104 10.3582 3.33719 10.125 3.62484 10.125H6.229V7.52083H5.44775C4.82615 7.52083 4.23001 7.2739 3.79047 6.83436C3.35093 6.39483 3.104 5.79868 3.104 5.17708C3.104 4.55548 3.35093 3.95934 3.79047 3.5198C4.23001 3.08026 4.82615 2.83333 5.44775 2.83333H6.229V1.27083C6.229 0.983185 6.46219 0.75 6.74984 0.75ZM6.229 3.875H5.44775C5.10242 3.875 4.77123 4.01218 4.52704 4.25637C4.28285 4.50056 4.14567 4.83175 4.14567 5.17708C4.14567 5.52242 4.28285 5.85361 4.52704 6.0978C4.77123 6.34198 5.10242 6.47917 5.44775 6.47917H6.229V3.875ZM7.27067 7.52083V10.125H8.05192C8.39725 10.125 8.72845 9.98782 8.97263 9.74363C9.21682 9.49944 9.354 9.16825 9.354 8.82292C9.354 8.47758 9.21682 8.14639 8.97263 7.9022C8.72845 7.65802 8.39725 7.52083 8.05192 7.52083H7.27067Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_14_72">
                                <rect width="12.5" height="12.5" fill="white" transform="translate(0.5 0.75)" />
                            </clipPath>
                        </defs>
                    </svg>
                        {t("PRICING")}</a></li>
                    <li className={`${pathname == "contact" ? "active" : ""}`}>
                        <a href="/contact"><svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.9375 6.7396C10.9393 7.42703 10.7787 8.10516 10.4687 8.71876C10.1013 9.45405 9.53633 10.0725 8.83721 10.5048C8.1381 10.9372 7.33242 11.1664 6.51042 11.1667C5.82299 11.1685 5.14485 11.0079 4.53125 10.6979L1.5625 11.6875L2.55208 8.71876C2.24215 8.10516 2.08154 7.42703 2.08333 6.7396C2.08365 5.9176 2.31282 5.11192 2.74517 4.4128C3.17751 3.71369 3.79597 3.14875 4.53125 2.78126C5.14485 2.47133 5.82299 2.31072 6.51042 2.31251H6.77083C7.85643 2.37241 8.88179 2.83062 9.65059 3.59942C10.4194 4.36822 10.8776 5.39358 10.9375 6.47918V6.7396Z" stroke="white" strokeWidth="1.08333" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                            {t("CONTACT US")}</a>
                    </li>
                    <li>
                        {!isLogged() ?  <button className="button-login height-36" onClick={SignIn}>{t("Login")}
                        </button> :
                            <a href="Dashboard">
                                <img src={user && user.profile ? user.profile : "/assets/images-v2/user-profile.webp"} className="nav-profile" alt="profile" /></a>}
                    </li>
                    <li>
                        {!isLogged() && <button className="button-signup button-signup-flex" onClick={SignUp}>{t("Sign Up")}
                        </ button>}
                    </li>
                    <li><a style={{ margin: "0px" }} onClick={() => changeLanguage('en')}>EN</a></li>
                    <li><a style={{ margin: "0px" }} onClick={() => changeLanguage('ar')}>AR</a></li>

                </ul>
            </div>
        </header>
    )
}

export default HeaderHome;