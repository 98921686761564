import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from "react-i18next";
import 'swiper/css';
import 'swiper/css/pagination';
import { FreeMode, Pagination } from 'swiper/modules';

const Features: React.FunctionComponent<{}> = (props) => {

	const { t, i18n } = useTranslation();

	return (
		<div className="slider9" >
			<div className="container" id="Key-Features">
				<div className="text-center  dir-rtl ">
					<h1 className='sec-9-title'>{t("Key Features")}</h1>
					<p className="sec-9-para">
					{t("Redesign, furnish, landscape, and convert").split("\n").map((item, index) => (
						<React.Fragment key={index}>
							{item}
							<br />
						</React.Fragment>
					))}
					</p>
				</div>
				<div>
					
				</div><Swiper
						slidesPerView={3}
						spaceBetween={30}
						freeMode={true}
						pagination={{
							clickable: true,
						}}
						breakpoints={{
							320: {
								slidesPerView: 1,
								spaceBetween: 10,
							},
							640: {
								slidesPerView: 2,
								spaceBetween: 20,
							},
							1024: {
								slidesPerView: 3,
								spaceBetween: 30,
							},
						}}
						modules={[FreeMode, Pagination]}
						className="mySwiper m-50"
					>
						<SwiperSlide>
							<div className="mb-4  dir-rtl">
								<div className='card-slider9'>
									<div className="txt-slide">
										<h3>{t("AI Room Staging")}</h3>
										<p>{t("Redesign interiors effortlessly")}</p>
									</div>
									<div className="img-holder">
										<img src="/assets/images-v2/blog9-qgcqjcn9d.webp" />
									</div>
									<div className="btn-holder">
										<a className="learn-more" href="#Room-Staging">{t("Learn more")}</a>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="mb-4  dir-rtl">
								<div className='card-slider9'>
									<div className="txt-slide">
										<h3>{t("Garden Landscaping")}</h3>
										<p>{t("Create your dream")}</p>
									</div>
									<div className="img-holder">
										<img src="/assets/images-v2/blog9-qgcqjc.webp" />
									</div>
									<div className="btn-holder">
										<a className="learn-more" href="#Features">{t("Learn more")}</a>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="mb-4  dir-rtl">
								<div className='card-slider9'>
									<div className="txt-slide">
										<h3>{t("2D to 3D Floor Plan")}</h3>
										<p>{t("Visualize your space in 3D")}</p>
									</div>
									<div className="img-holder">
										<img src="/assets/images-v2/plan-3d.webp" />
									</div>
									<div className="btn-holder">
										<a className="learn-more" href="#Features">{t("Learn more")}</a>
									</div>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div className="mb-4  dir-rtl">
								<div className='card-slider9'>
									<div className="txt-slide">
										<h3>{t("Style Transfer")}</h3>
										<p>{t("Apply any style by uploading")}</p>
									</div>
									<div className="img-holder">
										<img src="/assets/images-v2/BEFORE-1.webp" />
									</div>
									<div className="btn-holder">
										<a className="learn-more" href="#Features">{t("Learn more")}</a>
									</div>
								</div>
							</div>
						</SwiperSlide>

					</Swiper>
			</div>
		</div>
	)
}
export default Features;