import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { GetAccount, isLogged, logout, UpdateAccount } from '../../Services/Authentication';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const ButtonProfile: React.FunctionComponent<{}> = (props) => {

    const [openProfile, setOpenProfile] = useState(false);
    const [userImage, setUserImage] = useState();
    const [account, setAccount] = useState<any>("");
    const { t } = useTranslation();
    const savedLanguage = localStorage.getItem('language')
    const navigate = useNavigate();

    useEffect(() => {
        if (isLogged()) {
            GetAccount().then((resp) => {
                setAccount(resp)
                setUserImage(resp.profile)
            }).catch((error) => {
                console.log("error")
            })
        }
    }, []);

    const handleCloseProfile = () => {
        setOpenProfile(false);
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = async (e: ProgressEvent<FileReader>) => {
                const base_64 = e.target?.result as string;
                const ext = file.type.split('/')[1];
                const image = {
                    id: 0,
                    src: base_64,
                    ext: ext
                };
                try {
                    const resp = await UpdateAccount({ images: [image] });
                    setUserImage(resp.profile);
                } catch (error) {
                    console.error("Error updating account:", error);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const logoutUser = () => {
        logout();
        navigate("/login");
    }

    return (

        <>
            <div className="profile-container">
                <button className="but-profile" onClick={() => setOpenProfile(true)}>
                    <img
                        src={userImage ? userImage : "/assets/images-v2/user-profile.webp"}
                        alt="profile"
                    />
                </button>
                {isLogged() && <Dialog onClose={handleCloseProfile} open={openProfile}
                    PaperProps={{
                        sx: {
                            position: 'absolute',
                            top: 120,
                            ...(savedLanguage == "ar" ? { left: 60 } : { right: 60 }),
                            margin: 0,
                        },
                    }}
                >
                    <div className="profile-card  dir-rtl">
                        <div className='img-profile mb-3' >
                            <div className='' style={{ position: 'relative', display: 'inline-block' }}>
                                <img
                                    src={userImage ? userImage : "/assets/images-v2/user-profile.webp"}
                                    width="80px"
                                    height="80px"
                                    alt="Designer"
                                    style={{ borderRadius: '50%' }}
                                />
                                <input
                                    type="file"
                                    id="upload-profile"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                />
                                <button
                                    className="update-btn"
                                    onClick={() => {
                                        const uploadInput = document.getElementById('upload-profile');
                                        if (uploadInput) {
                                            uploadInput.click();
                                        }
                                    }}
                                    title="Update Image"
                                >
                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.03041 10.1241L19.0009 10.1241M10.5157 18.6094L10.5157 1.63892" stroke="#29BD81" strokeWidth="2.5" strokeLinecap="round" />
                                    </svg>

                                </button>
                            </div>
                            <h3>{account.name}</h3>
                        </div>
                        <div className='card-400'>
                            <div className='h-22'>{t("Email")}:</div>
                            <div className='h-23'>{account.email}</div>
                            <div className='h-22'>{t("Credit Score")}:</div>
                            <div className='h-23'>{account.creditScore}</div>
                            <div className='h-22'>{t("Package")}:</div>
                            <div className='h-23'><span className="badge-standard">{account.subscription}</span>
                            </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button className='log-out' onClick={logoutUser}>
                                <svg width="24" height="24" className="marg-right-10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 7L9 12L14 17" stroke="#098553" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 12L21 12" stroke="#098553" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19L3 5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="#098553" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>{t("Log Out")}
                            </button>
                        </div>
                    </div>
                </Dialog>}
            </div>
        </ >
    )
}

export default ButtonProfile;