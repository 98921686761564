import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar";

const LayoutDash: React.FunctionComponent<{}> = (props) => {

    return (
        <div className="container-scroller dir-rtl">
            <div className="page-body-wrapper" >
                <Sidebar />
                <div className="main-panel">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default LayoutDash