import React from 'react';
import { useTranslation } from "react-i18next";

const HowItWorks: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();

    return (

        <div className="work-sec card-work dir-rtl">
            <div className='container'>
                <div className=" text-center">
                    <h2 className="sec-9-title ">{t("How It Works")}</h2>
                </div>
                <div className="list-step">
                    <div className="box mb-4 large">
                        <button className="button-login height-button mt-4">{t("STEP 1")}</button>
                        <div className="features-tags mt-4">
                            <h3 className='marg-0 mb-3'>{t("Upload a Photo")}</h3>
                            <p className='marg-0'>{t("Upload aphoto of your interior")}</p>
                        </div>
                    </div>
                    <div className="box mb-4 small">
                        <svg width="70" height="16" viewBox="0 0 70 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M46.1965 7.6635C55.259 3.79284 57.7549 2.89268 64.175 1.17698C68.9871 -0.108324 70.2385 -0.235722 69.964 0.587697C69.6276 1.59656 59.9339 6.68221 49.4184 11.3669C37.5388 16.6592 35.9072 16.9518 31.9377 14.5007C29.8098 13.1864 27.218 10.4077 25.8644 7.98912C25.4389 7.22866 25.2023 7.23411 22.5328 8.06195C20.9524 8.55237 15.6036 10.4983 10.6473 12.3861C5.6906 14.2745 1.30348 15.6916 0.898167 15.5362C0.467766 15.3714 0.226271 14.7915 0.318254 14.1441C0.523863 12.697 4.10822 10.7713 14.0025 6.79387C20.522 4.17309 22.0833 3.72646 24.7768 3.70925L27.9264 3.68955L29.9923 6.64087C32.4975 10.2203 34.8679 11.9843 36.5925 11.5514C37.2619 11.3832 41.5836 9.634 46.1965 7.6635Z" fill="black" />
                        </svg>
                    </div>
                    <div className="box mb-4 large">
                        <button className="button-login height-button mt-4">{t("STEP 2")}</button>
                        <div className="features-tags mt-4">
                            <h3 className='marg-0 mb-3'>{t("Choose or Upload a Style")}</h3>
                            <p className='marg-0'>{t("Select from over 50 styles")}</p>
                        </div>
                    </div>
                    <div className="box mb-4 small">
                        <svg width="70" height="16" viewBox="0 0 70 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.8025 7.6635C14.74 3.79284 12.2441 2.89268 5.82404 1.17698C1.0119 -0.108324 -0.239479 -0.235722 0.034996 0.587697C0.371445 1.59656 10.0651 6.68221 20.5806 11.3669C32.4602 16.6591 34.0918 16.9518 38.0613 14.5007C40.1892 13.1864 42.781 10.4077 44.1347 7.98912C44.5601 7.22866 44.7967 7.23411 47.4662 8.06195C49.0466 8.55236 54.3954 10.4983 59.3517 12.3861C64.3084 14.2745 68.6955 15.6916 69.1009 15.5361C69.5313 15.3714 69.7728 14.7915 69.6808 14.1441C69.4752 12.697 65.8908 10.7713 55.9965 6.79387C49.477 4.17309 47.9158 3.72646 45.2222 3.70925L42.0727 3.68955L40.0068 6.64087C37.5016 10.2203 35.1311 11.9843 33.4066 11.5514C32.7371 11.3832 28.4154 9.63399 23.8025 7.6635Z" fill="black" />
                        </svg>
                    </div>
                    <div className="box mb-4 large">
                        <button className="button-login height-button mt-4">{t("STEP 3")}</button>
                        <div className="features-tags mt-4">
                            <h3 className='marg-0 mb-3'>{t("Transform Your Space")}</h3>
                            <p className='marg-0'>{t("Letour AI work its magic").split("\n").map((item, index) => (
                                <React.Fragment key={index}>
                                    {item}
                                    <br />
                                </React.Fragment>
                            ))}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HowItWorks;
