import { Route, Routes } from 'react-router-dom';
import Login from '../Component/Site/login';
import SignUp from '../Component/Site/sign-up';
import AuthGuard from '../Services/AuthGuard';
import History from '../Component/Dash/History/history';
import Profile from '../Component/Dash/profile';
import AuthGuardEn from '../Services/AuthGuardEn';
import { useEffect } from 'react';
import i18n from '../i18n';
import Layout from '../Component/Site/layout/layout';
import LayoutDash from '../Component/Dash/layout/layout-dash';
import Contact from '../Component/Site/contact';
import Home from '../Component/Site/home';
import PricingPage from '../Component/Site/pricing-page';
import VerifyAccount from '../Component/Site/VerifyAccount';
import TermsOfService from '../Component/Site/terms-service';
import PrivacyPolicy from '../Component/Site/privacy-policy';
import CancellationPolicy from '../Component/Site/cancellation-policy';
import Dashboard from '../Component/Dash/Dash/dash';
import ResetPassword from '../Component/Site/reset-password';
import ChangePassword from '../Component/Site/change-password';
import ScrollToTop from '../Component/Site/ScrollToTop';

const PublicRouter: React.FunctionComponent<{}> = (props) => {

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
            document.body.classList.toggle('rtl', savedLanguage === 'ar');
            document.body.classList.toggle('ltr', savedLanguage !== 'ar');
        }
    }, []);

    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<Layout />}>
                    <Route path="" element={<Home />} />
                    <Route path='verify' element={<VerifyAccount />} />
                    <Route path="home" element={<Home />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="login" element={<AuthGuardEn><Login /></AuthGuardEn>} />
                    <Route path='sign-up' element={<AuthGuardEn><SignUp /></AuthGuardEn>} />
                    <Route path="/account/reset/password" element={<AuthGuardEn><ResetPassword /></AuthGuardEn>} />
                    <Route path="/account/reset/password/:email" element={<AuthGuardEn><ChangePassword /></AuthGuardEn>} />
                    <Route path="pricing" element={<PricingPage />} />
                    <Route path="terms-of-service" element={<TermsOfService />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="cancellation-policy" element={<CancellationPolicy />} />
                    <Route path="*" element={<Home />} />
                </Route>

                <Route path='/' element={<LayoutDash />} >
                    <Route path="history" element={<AuthGuard><History /></AuthGuard>} />
                    <Route path="Dashboard" element={<AuthGuard><Dashboard /></AuthGuard>} />
                    <Route path="Dashboard/:state" element={<AuthGuard><Dashboard /></AuthGuard>} />
                    <Route path="/profile/:id" element={<AuthGuard><Profile /></AuthGuard>} />
                </Route>

            </Routes>
        </>
    )
}
export default PublicRouter;