import React, { useEffect, useState } from "react";
import { ServForgotPassword } from "../../Services/Authentication";
import { useTranslation } from "react-i18next";
import Header from "./layout/header";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";


const ResetPassword: React.FunctionComponent<{}> = (props) => {

    const [formData, setFormData] = useState<any>({
        email: ''
    });
    const [errors, setErrors] = useState<any>({
        email: ''
    });
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState<string>();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [colorSnackbar, setColorSnackbar] = useState<any>("");
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleCloseSnackbar = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleChangeEmail = (event: any) => {
        const emailValue = event.target.value;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            email: emailValue,
        }));
    }

    const handleResetPassword = (event: any) => {
        event.preventDefault()
        setIsSuccess(false);
        if (formData.email === "") {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Please enter your email address',
            }));
        }
        if (formData.email && (!/^[A-Za-z\._\-[0-9]*[@][A-Za-z]*[\.][a-z]{2,4}$/.test(formData.email))) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Please enter your email in the correct format',
            }));
        }
        else {
            setErrors({})
            ServForgotPassword(formData.email).then(res => {
                setIsSuccess(true)
                setMessage("Please check your email inbox for a link to complete the reset.");
                setColorSnackbar("success")
                setOpenSnackbar(true)
            }).catch(err => {
                setMessage(err.response.data.error);
                setColorSnackbar("error")
                setOpenSnackbar(true)
            })
        }
    }

    return (
        <>
            <Header />

            <div className="dir-rtl">
                <div className="login-sec" >
                    <div className="row text-center">
                        <h2 className="title-32">{t("Reset your password")}</h2>
                    </div>
                    <div className="container">
                        <div className="row marg-login justify-content-center">
                            <div className="col-md-7 signup-form ">
                                <form onSubmit={handleResetPassword}>
                                    <div className="form-group mb-4">
                                        <label htmlFor="email" className="label mb-3">*{t("Email Address")}</label>
                                        <input type="email" className="form-control" value={formData.email} onChange={handleChangeEmail} />
                                        {errors.email && <span className="error">{t(`${errors.email}`)}</span>}
                                    </div>
                                    {!isSuccess && <div className="sign-a justify-content-end">
                                        <button type="submit" className="btn">{t("Reset password")}</button>
                                    </div>
                                    }
                                </form>
                                {isSuccess && <div className="sign-a justify-content-end">
                                    <button type="submit" className="btn" onClick={() => (navigate(`/account/reset/password/${formData.email}`))}>{t("Next")}</button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={colorSnackbar || "info"}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {t(`${message}`)}
                </Alert>
            </Snackbar>

        </>
    )
}

export default ResetPassword;