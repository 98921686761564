import React, { useEffect, useRef, useState } from 'react';
import { Generate } from '../../../Models/Generate';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { isLogged } from '../../../Services/Authentication';
import { useTranslation } from 'react-i18next';
import { GetStyle } from '../../../Services/Generate';
import DesignStyles from './design-tyles';

const UploadImages: React.FunctionComponent<{ count: any, setCount: any, Feature: any, generateValue: any, onGenerate3DPlan: any, OnGenerateRoom: any, OnGenerateRoomStyle: any }> = (props) => {

    const [generateValue, setGenerateValue] = useState<Generate>(props.generateValue);
    const [selectedImages, setSelectedImages] = useState<any>([]);
    const [change, setChange] = useState<Boolean>(false);
    const [roomType, setRomeType] = useState<any>([{ value: "Bedroom", type: "Bedroom" },
    { value: "Bathroom", type: "Bathroom" },
    { value: "Living Room", type: "Living Room" },
    { value: "Kitchen", type: "Kitchen" }, { value: "Balcony", type: "Balcony" },
    { value: "Office Room", type: "Office Room" }, { value: "Dining Area", type: "Dining Area" },
    { value: "Terrace", type: "Terrace" }])
    const [style, setStyle] = useState<any>([{ value: "Eclectic", type: "Eclectic" },
    { value: "Cottage", type: "Cottage" },
    { value: "Scandinavian", type: "Scandinavian" },
    { value: "Coastal", type: "Coastal" }, { value: "Traditional", type: "Traditional" },
    { value: "Bohemian", type: "Bohemian" }, { value: "Art Deco", type: "Art Deco" },
    { value: "Contemporary", type: "Contemporary" },
    { value: "Modern", type: "Modern" }, { value: "Midcentury Modern", type: "Midcentury Modern" },
    { value: "Country", type: "Country-style" }, { value: "Shabby Chic", type: "Shabby Chic" },
    { value: "Modern Farmhouse", type: "Modern Farmhouse" }, { value: "Americana", type: "Americana" },
    { value: "French Country", type: "French Country" }, { value: "Transitional", type: "Transitional" },
    { value: "Hollywood Regency", type: "Hollywood Regency" }, { value: "Chinoiserie", type: "Chinoiserie" },
    { value: "Mediterranean", type: "Mediterranean" }, { value: "Tropical", type: "Tropical" },
    { value: "Minimalist", type: "Minimalist" }, { value: "Maximalist", type: "Maximalist" },
    { value: "Rustic", type: "Rustic" }, { value: "Romantic", type: "Romantic" },
    { value: "Asian", type: "Asian" }])
    const [styleGarden, setStyleGarden] = useState<any>([{ value: "Japanese Zen Garden", type: "Japanese Zen Garden" },
    { value: "Mediterranean Garden", type: "Mediterranean Garden" },
    { value: "Formal French Garden", type: "Formal French Garden" },
    { value: "Tropical Garden", type: "Tropical Garden" }, { value: "Desert Garden", type: "Desert Garden" },
    { value: "Modern/Contemporary Garden", type: "Modern/Contemporary Garden" }, { value: "Wildflower Meadow", type: "Wildflower Meadow" },
    { value: "Cottage Garden", type: "Cottage Garden" },
    { value: "Rock Garden", type: "Rock Garden" }, { value: "Xeriscape Garden", type: "Xeriscape Garden" },
    { value: "Native Plant Garden", type: "Native Plant Garden" }, { value: "English Cottage Garden", type: "English Cottage Garden" }])
    const { t } = useTranslation();
    const [listStyle, setListStyle] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef<IntersectionObserver | null>(null);
    const lastElementRef = useRef<HTMLDivElement>(null);

    const FetchStyles = () => {
        GetStyle(generateValue.style, props.Feature, page).then((response) => {
            const { listImages, total, perPage, currentPage } = response;
            setListStyle((prevImages: any) => [...prevImages, ...listImages]);
            setHasMore(currentPage * perPage < total);
        }).catch((error) => {
            console.error(error);
        });
    };

    useEffect(() => {
        setPage(1);
        setListStyle([]);
        if (isLogged() && (props.Feature === "Garden Landscaping" || props.Feature === "Room Staging")) {
            FetchStyles();
        }
    }, [props.Feature]);

    useEffect(() => {
        if (isLogged() && (props.Feature === "Garden Landscaping" || props.Feature === "Room Staging")) {
            FetchStyles();
        }
    }, [change, page]);

    useEffect(() => {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage((prevPage) => prevPage + 1);
            }
        });
        if (lastElementRef.current) observer.current.observe(lastElementRef.current);
    }, [hasMore]);


    const handleChangeRoomType = (event: any) => {
        setGenerateValue((prevFormData: any) => ({
            ...prevFormData,
            room_type: event.target.value,
        }))
    }

    const handleChangeStyle = (event: any) => {
        setGenerateValue((prevFormData: any) => ({
            ...prevFormData,
            style: event.target.value,
        }))
        setListStyle([])
        setPage(1)
        setChange(!change)
    }

    const handleGetStyle = (event: any) => {
        setGenerateValue((prevFormData: any) => ({
            ...prevFormData,
            style: event.target.value,
        }))
        setChange(!change)
        setListStyle([])
        setPage(1)
        setChange(!change)
    }

    const handleFileUpload = (event: any) => {
        const files = event.target.files;
        const images_List: any = []
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]);
            reader.onload = () => {
                const base_64 = reader.result as string;
                const ext = event.target.files[i].type.split('/');
                let image = {
                    "id": i,
                    "src": base_64,
                    "ext": ext[1]
                }
                images_List.push(image)
                setSelectedImages((prevSelectedImages: any) =>
                    [...prevSelectedImages, image]
                );

            };
        }
        props.setCount(0)
        setGenerateValue((prevFormData: any) => ({
            ...prevFormData,
            images: images_List,
            uploaded: true
        }))
    }

    const handleFileUploadStyle = (event: any) => {
        const files = event.target.files;
        const images_List: any = []
        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[i]);
            reader.onload = () => {
                const base_64 = reader.result as string;
                const ext = event.target.files[i].type.split('/');
                let image = {
                    "id": i,
                    "src": base_64,
                    "ext": ext[1]
                }
                images_List.push(image)
                setSelectedImages((prevSelectedImages: any) =>
                    [...prevSelectedImages, image]
                );
            };
        }
        setGenerateValue((prevFormData: any) => ({
            ...prevFormData,
            style_image: images_List,
        }))
    }

    const generate3DPlan = () => {
        props.onGenerate3DPlan({ images: generateValue?.images, uploaded: true })
    }

    const generateRoom = () => {
        const ObjValue = props.Feature === "Garden Landscaping"
            ? { environment: "Garden", room_type: "", images: generateValue?.images, uploaded: generateValue?.uploaded, style: generateValue?.style }
            : { environment: "interior", room_type: generateValue?.room_type, images: generateValue?.images, uploaded: generateValue?.uploaded, style: generateValue?.style, count: props.count };
        props.OnGenerateRoom(ObjValue)
    }

    const generateRoomStyle = () => {
        props.OnGenerateRoomStyle({
            images: generateValue?.images,
            style_image: generateValue?.style_image,
            uploaded: generateValue?.uploaded
        })
    }


    return (
        <>
            <div className='row m-30'>
                <h2 className='mb-3'>{t(`${props.Feature}`)}</h2>
                <h4 className='mb-4'>{t("Upload Room Photo")}</h4>
            </div>
            <div className="card-upload mb-5">
                <div className="row d-flex align-items-center">
                    <div className=" col-md-7">
                        {generateValue?.images && generateValue?.images.length == 0 ?
                            <label id="upload-photo" onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    if (e.dataTransfer && e.dataTransfer.files) {
                                        handleFileUpload({ target: { files: e.dataTransfer.files } });
                                    }
                                }}>
                                <svg width="120" height="124" viewBox="0 0 120 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M90 41.1687C90 46.8361 85.5228 51.4304 80 51.4304C74.4772 51.4304 70 46.8361 70 41.1687C70 35.5012 74.4772 30.9069 80 30.9069C85.5228 30.9069 90 35.5012 90 41.1687Z" fill="#29BD81" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M59.7131 6.53516H60.2869C71.8288 6.53509 80.8741 6.53504 87.9315 7.50873C95.1552 8.50535 100.855 10.5851 105.329 15.1763C109.803 19.7674 111.83 25.6168 112.801 33.0296C113.75 40.2718 113.75 49.5537 113.75 61.3979V61.8508C113.75 71.6446 113.75 79.662 113.232 86.1899C112.711 92.7498 111.643 98.2315 109.254 102.784C108.201 104.792 106.906 106.59 105.329 108.208C100.855 112.799 95.1552 114.879 87.9315 115.876C80.874 116.849 71.8288 116.849 60.2869 116.849H59.7131C48.1712 116.849 39.126 116.849 32.0685 115.876C24.8449 114.879 19.1447 112.799 14.6707 108.208C10.7043 104.138 8.65599 99.0719 7.56676 92.7876C6.49675 86.6143 6.30101 78.9336 6.26035 69.3961C6.25 66.9702 6.25 64.4043 6.25 61.6974V61.3978C6.24994 49.5538 6.24989 40.2717 7.19874 33.0296C8.16993 25.6168 10.1966 19.7674 14.6707 15.1763C19.1447 10.5851 24.8449 8.50535 32.0685 7.50873C39.1259 6.53504 48.1712 6.53509 59.7131 6.53516ZM33.0679 15.1364C26.6759 16.0183 22.8223 17.6956 19.974 20.6184C17.1257 23.5413 15.4912 27.4957 14.6319 34.0551C13.758 40.7251 13.75 49.4898 13.75 61.6922C13.75 63.1828 13.75 64.6244 13.7517 66.0201L18.7574 61.5255C23.3137 57.4344 30.1808 57.669 34.4619 62.0622L55.9105 84.0723C59.3467 87.5984 64.7557 88.0792 68.7315 85.2119L70.2225 84.1366C75.9437 80.0106 83.6841 80.4886 88.8818 85.289L103.034 98.3593C104.459 95.2893 105.305 91.2554 105.756 85.5648C106.247 79.3848 106.25 71.6763 106.25 61.6922C106.25 49.4898 106.242 40.7251 105.368 34.0551C104.509 27.4957 102.874 23.5413 100.026 20.6184C97.1777 17.6956 93.3241 16.0183 86.9321 15.1364C80.4322 14.2397 71.8911 14.2315 60 14.2315C48.1089 14.2315 39.5678 14.2397 33.0679 15.1364Z" fill="#29BD81" />
                                </svg>
                                <h3 className="upload-h3">{t("Drag & drop Photos or")} <span>{t("Browse")}</span></h3>
                                <p className="desc-upload">{t("Support .jpg and png files")}</p>
                                <input className="form__file" id="upload-images" accept="image/*" type="file"
                                    onChange={handleFileUpload} />
                            </label>
                            :
                            <div id="read-photo"  >
                                {generateValue?.images && generateValue?.images.map((image: any, index: any) => {
                                    return <img src={image.src} className="previewImage" key={index} />
                                })
                                }
                                <input
                                    id="input-file"
                                    name="input-file"
                                    accept="image/*" type="file" multiple
                                    style={{ opacity: 0, display: "none" }}
                                    onChange={handleFileUpload}
                                />
                                <label className="upload-button" htmlFor="input-file" role="button"  >
                                    {t("Upload Image")}
                                </label>
                            </div>}
                    </div>
                    <div className="col-md-5 ">
                        {props.Feature === "2D to 3D Floor Plan" ? "" : <h4 className='mb-1 font-size-24'>{t("Customise")}</h4>}

                        {props.Feature === "Room Staging" && <>
                            <label className='mb-2 mt-3'>{t("Room Type")}</label>
                            <FormControl fullWidth>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select Desired Room Type' }}
                                    value={generateValue?.room_type}
                                    onChange={handleChangeRoomType}
                                    sx={{
                                        fontFamily: 'Outfit',
                                        height: "56px",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#666666",
                                        border: "1px solid rgba(102, 102, 102, 0.35)",
                                        borderRadius: "12px",
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#2EBB84',
                                            borderRadius: "12px",
                                        }
                                    }}
            
                                >
                                    <MenuItem value="" >
                                        {t("Select Desired Room Type")}
                                    </MenuItem>
                                    {roomType.map((room: any, index: any) => {
                                        return <MenuItem key={index} value={room.value}>{t(`${room.type}`)}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <label className='mb-2 mt-3'>{t("Room Style")}</label>
                            <FormControl fullWidth>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select Desired Style' }}
                                    value={generateValue?.style}
                                    onChange={handleChangeStyle}
                                    sx={{
                                        fontFamily: 'Outfit',
                                        height: "56px",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#666666",
                                        border: "1px solid rgba(102, 102, 102, 0.35)",
                                        borderRadius: "12px",
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#2EBB84',
                                            borderRadius: "12px",
                                        }
                                    }}
            
                                >
                                    <MenuItem value="" >
                                        {t("Select Desired Style")}
                                    </MenuItem>
                                    {style.map((obj: any, index: any) => {
                                        return <MenuItem key={index} value={obj.value}>{t(`${obj.type}`)} </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <div className='d-flex justify-content-center'>
                                <button  id="generate" className={`generate mar-40 ${(generateValue?.room_type && generateValue?.style && generateValue?.images?.length !== 0) ? "" : "disabled"}`} onClick={generateRoom}>{t("Generate")}</button>
                            </div>
                        </>
                        }

                        {props.Feature === "Garden Landscaping" && <>
                            <label className='mb-2 mt-3'>{t("Garden Style")}</label>
                            <FormControl fullWidth>
                                <Select
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Select Desired Garden Style' }}
                                    value={generateValue?.style}
                                    onChange={handleChangeStyle}
                                    sx={{
                                        fontFamily: 'Outfit',
                                        height: "56px",
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#666666",
                                        border: "1px solid rgba(102, 102, 102, 0.35)",
                                        borderRadius: "12px",
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#2EBB84',
                                            borderRadius: "12px",
                                        }
                                    }}
            
                                >
                                    <MenuItem value="" >
                                        {t("Select Desired Garden Style")}
                                    </MenuItem>
                                    {styleGarden.map((obj: any, index: any) => {
                                        return <MenuItem key={index} value={obj.value}>{t(`${obj.type}`)} </MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <div className='d-flex justify-content-center'>
                                <button  id="generate" className={`generate mar-40 ${(generateValue?.style && generateValue?.images?.length !== 0) ? "" : "disabled"}`} onClick={generateRoom}>{t("Generate")}</button>
                            </div>
                        </>
                        }

                        {props.Feature === "2D to 3D Floor Plan" && <>
                            <div className='d-flex justify-content-center'>
                                <button  id="generate" className={`generate mar-40 ${(generateValue?.images?.length !== 0) ? "" : "disabled"}`} onClick={generate3DPlan}>{t("Generate")}</button>
                            </div>
                        </>
                        }

                        {props.Feature === "Style Transfer" && <>
                            <label className='mb-2 mt-3'>{t("Upload photo of your preferred style")}</label>
                            {generateValue?.style_image && generateValue?.style_image.length == 0 ? <label id="upload-photo" className='height-upload mt-2' onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => {
                                    e.preventDefault();
                                    if (e.dataTransfer && e.dataTransfer.files) {
                                        handleFileUploadStyle({ target: { files: e.dataTransfer.files } });
                                    }
                                }}>
                                <svg width="120" height="124" viewBox="0 0 120 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M90 41.1687C90 46.8361 85.5228 51.4304 80 51.4304C74.4772 51.4304 70 46.8361 70 41.1687C70 35.5012 74.4772 30.9069 80 30.9069C85.5228 30.9069 90 35.5012 90 41.1687Z" fill="#29BD81" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M59.7131 6.53516H60.2869C71.8288 6.53509 80.8741 6.53504 87.9315 7.50873C95.1552 8.50535 100.855 10.5851 105.329 15.1763C109.803 19.7674 111.83 25.6168 112.801 33.0296C113.75 40.2718 113.75 49.5537 113.75 61.3979V61.8508C113.75 71.6446 113.75 79.662 113.232 86.1899C112.711 92.7498 111.643 98.2315 109.254 102.784C108.201 104.792 106.906 106.59 105.329 108.208C100.855 112.799 95.1552 114.879 87.9315 115.876C80.874 116.849 71.8288 116.849 60.2869 116.849H59.7131C48.1712 116.849 39.126 116.849 32.0685 115.876C24.8449 114.879 19.1447 112.799 14.6707 108.208C10.7043 104.138 8.65599 99.0719 7.56676 92.7876C6.49675 86.6143 6.30101 78.9336 6.26035 69.3961C6.25 66.9702 6.25 64.4043 6.25 61.6974V61.3978C6.24994 49.5538 6.24989 40.2717 7.19874 33.0296C8.16993 25.6168 10.1966 19.7674 14.6707 15.1763C19.1447 10.5851 24.8449 8.50535 32.0685 7.50873C39.1259 6.53504 48.1712 6.53509 59.7131 6.53516ZM33.0679 15.1364C26.6759 16.0183 22.8223 17.6956 19.974 20.6184C17.1257 23.5413 15.4912 27.4957 14.6319 34.0551C13.758 40.7251 13.75 49.4898 13.75 61.6922C13.75 63.1828 13.75 64.6244 13.7517 66.0201L18.7574 61.5255C23.3137 57.4344 30.1808 57.669 34.4619 62.0622L55.9105 84.0723C59.3467 87.5984 64.7557 88.0792 68.7315 85.2119L70.2225 84.1366C75.9437 80.0106 83.6841 80.4886 88.8818 85.289L103.034 98.3593C104.459 95.2893 105.305 91.2554 105.756 85.5648C106.247 79.3848 106.25 71.6763 106.25 61.6922C106.25 49.4898 106.242 40.7251 105.368 34.0551C104.509 27.4957 102.874 23.5413 100.026 20.6184C97.1777 17.6956 93.3241 16.0183 86.9321 15.1364C80.4322 14.2397 71.8911 14.2315 60 14.2315C48.1089 14.2315 39.5678 14.2397 33.0679 15.1364Z" fill="#29BD81" />
                                </svg>
                                <h3 className="upload-h3">{t("Drag & drop Photos or")} <span>{t("Browse")}</span></h3>
                                <p className="desc-upload">{t("Support .jpg and png files")}</p>
                                <input className="form__file" id="upload-images" accept="image/*" type="file"
                                    onChange={handleFileUploadStyle} />
                            </label>
                                :
                                <div id="read-photo" className='mt-2'>
                                    {generateValue?.style_image && generateValue?.style_image.map((image: any, index: any) => {
                                        return <img src={image.src} className="previewImage" key={index} />
                                    })
                                    }
                                    <input
                                        id="input-file-2"
                                        name="input-file-2"
                                        accept="image/*" type="file"
                                        style={{ opacity: 0, display: "none" }}
                                        onChange={handleFileUploadStyle}
                                    />
                                    <label className="upload-button" htmlFor="input-file-2" role="button">
                                        {t("Upload Image")}
                                    </label>
                                </div>}
                            <div className='d-flex justify-content-center'>
                                <button  id="generate" className={`generate mar-40 ${(generateValue?.style_image?.length !== 0) ? "" : "disabled"}`} onClick={generateRoomStyle}>{t("Generate")}</button>
                            </div>
                        </>}

                    </div>
                </div>
            </div>
            {isLogged() && (props.Feature === "Room Staging" || props.Feature === "Garden Landscaping") && <>
                <div className='row m-30'>
                    <div className='col-8'>
                        <h2 className='mb-4'>{t("Explore Design Styles")}</h2>
                    </div>
                    <div className='col-4'>
                        <FormControl fullWidth>
                            <Select
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select Desired Style' }}
                                value={generateValue?.style}
                                onChange={handleGetStyle}
                                sx={{
                                    fontFamily: 'Outfit',
                                    height: "56px",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "#666666",
                                    border: "1px solid rgba(102, 102, 102, 0.35)",
                                    borderRadius: "12px",
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2EBB84',
                                        borderRadius: "12px",
                                    }
                                }}
        
                            >
                                <MenuItem value="" >
                                    {t("Style")}
                                </MenuItem>
                                {props.Feature === "Room Staging" && style.map((obj: any, index: any) => {
                                    return <MenuItem key={index} value={obj.value}>{t(`${obj.type}`)} </MenuItem>
                                })}
                                {props.Feature === "Garden Landscaping" && styleGarden.map((obj: any, index: any) => {
                                    return <MenuItem key={index} value={obj.value}>{t(`${obj.type}`)} </MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <DesignStyles listStyle={listStyle} />

                {hasMore && <div ref={lastElementRef} style={{ textAlign: "center" }}>Loading...</div>}
            </>
            }
        </>
    )
}
export default UploadImages;