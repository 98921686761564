import React, {useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import UpgradePrice from './upgrade-price';
import { useTranslation } from 'react-i18next';

const Upgrade: React.FunctionComponent<{class:any, User :any}> = (props) => {

    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();


    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
           	<Button  onClick={() => setOpen(true)} id={props.class}>{t("Upgrade")}</Button>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth={true}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        height: '100vh',
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" className=' pdd-20-24'>
                    <div className='title-dialog'>
                        <h4 className='justify-content-start'>
                            {t("Subscription")} </h4>
                        <div className='close justify-content-end' onClick={handleClose}>
                            <svg width="24" height="24" className='svg-end' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 17.9999L17.9999 6" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.9999 17.9999L6 6" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                  
                    <UpgradePrice User={props.User}/>

                </DialogContent>
                <DialogActions className='pdd-20-24'>

                </DialogActions>
            </Dialog>
        </ >
    )
}

export default Upgrade;