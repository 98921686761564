import React, { useEffect, useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import DetailsPrincing from '../Site/details-princing';
const Url = process.env.REACT_APP_URl;

const UpgradePrice: React.FunctionComponent<{ User: any }> = (props) => {

    const [selected, setSelected] = useState('Yearly');
      const [listPriceMonthly, setListPriceMonthly] = useState<any>([0, 19.99, 49.99, 99.99]);
      const [listPriceYearly, setListPriceYearly] = useState<any>([0, 14.99, 39.99, 79.99])
    const [discountApplied, setDiscountApplied] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (selected === "Yearly") {
            handleChangeSelected("Yearly")
            for (let index = 0; index < listPriceYearly.length; index++) {
                const element = listPriceYearly[index];
                CalculTotal(element)
            }
        }
    })

    const handleChangeSelected = (value: string) => {
        setSelected(value);
        if (value === 'Yearly' && !discountApplied) {
            setListPriceYearly([0, 14.99, 39.99, 79.99]);
            setDiscountApplied(true);
        } else if (value !== 'Yearly') {
            setListPriceYearly([0, 19.99, 49.99, 99.99]);
            setDiscountApplied(false);
        }
    };

    const CalculTotal = (value: any) => {
        const total = value * 12
        return parseFloat(total.toFixed(2))
    }

    const handlePyment = async (value: any) => {
        const stripe = await loadStripe('pk_live_51PFD6iGzYmczk5yZEgPY10US28UcaFf1YEysZP9SVH0KSeNRGtwt0JtHal3YiXVwq9ZaBOBow5iw6MCs2E62ZwA400A2R7JaDG');
        const { data: { sessionId } } = await axios.post(Url + "/api/create-checkout-session", {
            accountId: props.User._id,
            priceId: value
        });
        if (stripe) {
            const { error } = await stripe.redirectToCheckout({ sessionId });

            if (error) {
                console.error(error);
            }
        } else {
            console.error('Stripe has not loaded');
        }
    };


    return (
        <div className='service-plan '>
            <div className="pricing-toggle">
                <div className="toggle-container">
                    <button
                        className={`toggle-option ${selected === 'Monthly' ? 'active' : ''}`}
                        onClick={() => handleChangeSelected('Monthly')}
                    >
                        {t("Monthly")}
                    </button>
                    <svg width="2" height="17" viewBox="0 0 2 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.980469" y1="0.731445" x2="0.980469" y2="16.7314" stroke="#D1D1D1" />
                    </svg>

                    <button
                        className={`toggle-option ${selected === 'Yearly' ? 'active' : ''}`}
                        onClick={() => handleChangeSelected('Yearly')}
                    >
                        {t("Yearly")}
                    </button>
                </div>
                <div className="savings-note visb">
                        <div className="arrow dir-rtl">
                            <svg width="44" height="56" className='arrow' viewBox="0 0 44 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_72_2055)">
                                    <path d="M3.34433 5.70398C3.32444 7.10583 3.30455 8.50768 3.28625 10.1715C3.80298 9.86328 4.16411 9.66951 4.51053 9.3909C11.9219 3.8221 20.8365 3.62973 28.5408 8.84771C33.4703 12.2311 36.5254 16.9449 38.7041 22.4226C41.1285 28.5569 41.0742 34.8154 39.5353 41.1131C38.738 44.3542 36.9713 47.0642 34.7626 49.5015C34.1696 50.1288 33.5693 50.7137 32.6914 51.2156C32.8733 50.747 32.9979 50.201 33.3369 49.88C41.3505 41.7158 40.8202 25.7699 32.8655 15.5699C29.7579 11.5644 25.7346 8.854 20.7899 7.65821C14.9773 6.26345 8.03574 8.47322 4.25525 12.9749C6.30388 13.406 8.65575 12.2986 10.5641 14.1961C7.82425 14.5842 5.34722 14.9704 2.90542 15.3068C2.08903 15.4047 1.37671 15.0914 1.45709 14.2908C1.68193 11.5424 1.99929 8.8216 2.36658 6.13586C2.37972 5.95878 2.74821 5.80743 2.93903 5.64321C3.01683 5.58601 3.20187 5.6413 3.34433 5.70398Z" fill="#29BD81" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_72_2055">
                                        <rect width="50" height="35.9184" fill="white" transform="translate(35.3896) rotate(80.1548)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p className='title'>{t("SAVE UP TO 30%")} </p>
                    </div>
            </div>

            <div className='row dir-rtl mb-4'>
                <div className='col-md-3 '>
                    <div className='card-upg'>
                        <div className='title-pricing'>
                            <h4>{t("Free")}</h4>
                            <p>{t("For users starting out with free")}</p>
                        </div>
                        <div className="price">
                            <span className="span1">${listPriceYearly[0]}</span>
                            <span className="span2">{t("/month")}</span>
                        </div>
                        {selected === "Yearly" && <div className="price">
                            <span className="span2 font-14"></span>
                            <span className="span1 font-14"></span>
                        </div>}
                        <h5 className='desc-h5'>{t("Free plan features")}</h5>
                        <div className="desc marg-b-50">
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                    <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                </svg>

                            </span>
                            60 {t("Credits")}
                        </div>
                        <button className={`height-p-price  ${props.User.subscription == "free" || props.User.subscription ? "disabled" : ""}`} onClick={() => handlePyment("")}>
                            {t("Choose plan")}
                        </button>
                    </div>
                </div>
                <div className='col-md-3 mar-mobile'>
                    <div className='card-upg'>
                        <div className='title-pricing'>
                            <h4>{t("Basic")}</h4>
                            <p>{t("For users who wants to be a little bit more serious")}</p>
                        </div>

                        <div className="price mb-1">
                            <span className="span1">${listPriceYearly[1]}</span>
                            <span className="span2">{t("/month")}</span>
                        </div>
                        {selected === "Yearly" && <div className="price">
                            {selected === "Yearly" && <span className="span3">{listPriceMonthly[1]}$</span>}
                            <span className="span2 font-14" >{t("Total")}: </span>
                            <span className="span1 font-14"> ${CalculTotal(listPriceYearly[1])}</span>
                        </div>}
                        <h5 className='desc-h5'>{t("Basic plan features")}</h5>
                        <div className="desc marg-b-50">
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                    <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                </svg>

                            </span>
                            250 {t("Credits")}
                        </div>
                        <button className={`height-p-price  ${(props.User.subscription !== "basic" || props.User.subscription == "free") ? "" : "disabled"}`} onClick={() => handlePyment(selected === "Yearly" ? "price_1QQXADGzYmczk5yZsqAMXFIe" : "price_1QQX6rGzYmczk5yZdHikapNe")}>
                            {t("Choose plan")}
                        </button>
                    </div>
                </div>
                <div className='col-md-3 mar-mobile'>
                    <div className='card-upg back-29BD81-1'>
                        <div className='title-pricing'>
                            <h4 className='color-white'>{t("Advanced")}</h4>
                            <p className='color-white'>{t("For more advances features")}</p>
                        </div>
                        <div className="price">
                            <span className="span1 color-white">${listPriceYearly[2]}</span>
                            <span className="span2 color-white">{t("/month")} </span>
                        </div>
                        {selected === "Yearly" && <div className="price">
                            {selected === "Yearly" && <span className="span3 color-white">${listPriceMonthly[2]}</span>}
                            <span className="span2 font-14 color-white" >{t("Total")}: </span>
                            <span className="span1 font-14 color-white"> ${CalculTotal(listPriceYearly[2])}</span>
                        </div>}
                        <h5 className='desc-h5 color-white'>{t("Advanced plan features")}</h5>

                        <div className="desc color-white marg-b-50">
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="white" />
                                    <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="#29BD81" />
                                </svg>

                            </span>
                            1000 {t("Credits")}
                        </div>

                        <button className={`height-p-price button-login-white  ${(props.User.subscription !== "Advanced" || props.User.subscription === "free") ? "" : "disabled"}`} onClick={() => handlePyment(selected === "Yearly" ? "price_1QQXBBGzYmczk5yZ4bChDjhv" : "price_1QQX7dGzYmczk5yZ7yVARfo8")}>
                            {t("Choose plan")}
                        </button>
                    </div>
                </div>
                <div className='col-md-3 mar-mobile'>
                    <div className='card-upg'>
                        <div className='title-pricing'>
                            <h4>{t("Professional")}</h4>
                            <p>{t("For professionals and individuals")}</p>
                        </div>
                        <div className="price">
                            <span className="span1">${listPriceYearly[3]}</span>
                            <span className="span2">{t("/month")}</span>
                        </div>
                        {selected === "Yearly" && <div className="price">
                            {selected === "Yearly" && <span className="span3">${listPriceMonthly[3]}</span>}
                            <span className="span2 font-14" >{t("Total")}: </span>
                            <span className="span1 font-14"> ${CalculTotal(listPriceYearly[3])}</span>
                        </div>}
                        <h5 className='desc-h5'>{t("Professional plan features")}</h5>

                        <div className="desc marg-b-50">
                            <span>
                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="0.0986328" width="20.1933" height="20.7911" rx="10.0966" fill="black" />
                                    <path d="M8.59352 12.4394L13.8061 7.22675C13.9291 7.10374 14.0727 7.04224 14.2367 7.04224C14.4007 7.04224 14.5442 7.10374 14.6672 7.22675C14.7902 7.34976 14.8517 7.49594 14.8517 7.66529C14.8517 7.83463 14.7902 7.98061 14.6672 8.10321L9.02406 13.7617C8.90105 13.8847 8.75754 13.9462 8.59352 13.9462C8.42951 13.9462 8.28599 13.8847 8.16298 13.7617L5.51824 11.117C5.39523 10.994 5.33618 10.848 5.3411 10.6791C5.34602 10.5101 5.4102 10.364 5.53362 10.2405C5.65704 10.1171 5.80322 10.0556 5.97215 10.056C6.14109 10.0564 6.28706 10.1179 6.41007 10.2405L8.59352 12.4394Z" fill="white" />
                                </svg>

                            </span>
                            3000 {t("Credits")}
                        </div>
                        <button className={`height-p-price  ${(props.User.subscription !== "professional" || props.User.subscription == "free") ? "" : "disabled"}`} onClick={() => handlePyment(selected === "Yearly" ? "price_1QQXC1GzYmczk5yZ7AY0bfBx" : "price_1QQX89GzYmczk5yZQNX98k5u")}>
                            {t("Choose plan")}
                        </button>
                    </div>
                </div>
            </div>

            <div className='container dir-rtl mt-4'>
                <DetailsPrincing />
            </div>
        </div>
    )
}

export default UpgradePrice;