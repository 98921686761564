import React, { useState } from 'react';
import { DownloadEnhancedImage } from '../../../Services/Generate';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountObj } from '../../../Models/Account';


const Result: React.FunctionComponent<{ generatedimages: any , User :AccountObj}> = (props) => {

    
    const [isSpinnerDown, setIsSpinnerDown] = useState<boolean>(false);
    const { t } = useTranslation();



    const handleDownload = async (url: any) => {
        setIsSpinnerDown(true)
        const imageName = 'image.jpg';
        const urlImg = `https://www.api-v1.coralytics.com` + url
        try {
            const response = await fetch(urlImg);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = imageName;
            a.click();
            setIsSpinnerDown(false)
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    }

    const handleDownloadEnhance = (url: any, id: any) => {
        const download = document.getElementById("download-button");
        const image = {
            "image": "https://www.api-v1.coralytics.com" + url,
            "id": id
        }
        const imageName = 'image-enhanced.jpg';
        if (download) {
            download.style.display = "block";
            setIsSpinnerDown(true)
            DownloadEnhancedImage(image).then(async (resp) => {
                try {
                    const response = await fetch(resp.src);
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = imageName;
                    a.click();
                    setIsSpinnerDown(false)
                    window.URL.revokeObjectURL(url);
                } catch (error) {
                    console.error('Error downloading image:', error);
                }
            })
        }
    }


    return (
        <>
            {props.generatedimages.list_images && props.generatedimages.list_images.map((image: any, index: any) => {
                return <div className="card-dash mb-3" key={index}>
                    <div className="card-body-dash">
                        <div className="row  marg-disp">
                            <div className="col-md-6 mb-4">
                                <div className="image-hover">
                                    <img src={image.img_before.src} key={"after_" + index} className="img-his" id="img-before" />
                                    <div className="header-img--block header-img--block-before">{t("Before")}</div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="image-hover">
                                    <img src={`https://www.api-v1.coralytics.com` + image.img_after.src} key={"before_" + index} className="img-his" id="img-after" />
                                    <div className="header-img--block header-img--block-after">{t("After")}</div>
                                    <div className="dropdown" style={{ position: "static" }}>
                                        <button className="download-button" id="download-button">
                                            {isSpinnerDown ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Box sx={{ display: "flex" }}>
                                                    <CircularProgress size={30} sx={{ color: "white" }} />
                                                </Box></div> : <i className="fa-solid fa-download"></i>}
                                        </button>
                                        <div className="dropdown-content display-download">
                                            <a style={{ textAlign: "center" }} onClick={() => handleDownload(image.img_after.src)}>{t("Original")}</a>
                                            {props.User.subscription!=="free" &&  <a style={{ textAlign: "center" }} onClick={() => handleDownloadEnhance(image.img_after.src, props.generatedimages._id)}>
                                                <Tooltip title={<h1 style={{ fontSize: "16px", color: "#FFF", padding: "10px" }}>{t("Selecting this option will use up 20 credits")}</h1>} >
                                                    <IconButton>
                                                        <i className="fa-solid fa-circle-info"></i>
                                                    </IconButton>
                                                </Tooltip>{t("High Quality")}</a>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })}

        </>

    )
}

export default Result;