import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "./layout/header";
import { ServResetPassword } from "../../Services/Authentication";
import { useNavigate, useParams } from "react-router-dom";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ChangePassword: React.FunctionComponent<{}> = (props) => {

    const [message, setMessage] = useState<string>();
    const [colorSnackbar, setColorSnackbar] = useState<any>("");
    const { t, i18n } = useTranslation();
    const [confirmPassword, setConfirmPassword] = useState<string>();
    const { email } = useParams();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const navigate = useNavigate();

    const [formData, setFormData] = useState<any>({
        email: email,
        password: "",
        code: ""
    });
    const [errors, setErrors] = useState<any>({
        email: '',
        password: "",
        confirmPassword: "",
        code: ""
    });

    const handleCloseSnackbar = (
        event?: React.SyntheticEvent | Event,
        reason?: SnackbarCloseReason,
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prev: any) => ({ ...prev, [name]: value }));
    };

    const handleConfirmPassword = (e: any) => {
        const { value } = e.target;
        setConfirmPassword(value)
    }

    const handleChangePassword = (event: any) => {
        event.preventDefault();
        if (!formData.code) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                code: 'Please enter your code',
            }));
        }
        if (!formData.password) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                password: 'Please enter your password',
            }));
        }
        if (formData.password && (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(formData.password))) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                password: 'Password must be at least 8 characters, one letter, one number',
            }));
        }
        if (!confirmPassword) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                confirmPassword: 'Confirmation password is required',
            }));
        }
        if (formData.password !== confirmPassword) {
            setErrors((prevFormData: any) => ({
                ...prevFormData,
                confirmPassword: 'Passwords do not match',
            }));
        }
        else {
            setErrors({})
            ServResetPassword({ "email": email, "validationCode": formData.code, "newPassword": formData.password }).then(res => {
                setMessage(res.message);
                setColorSnackbar("success")
                setOpenSnackbar(true)
                setTimeout(() => {
                    navigate("/login")
                }, 2000)
            }).catch(err => {
                setMessage(err.response.data.error);
                setColorSnackbar("error")
                setOpenSnackbar(true)
            })
        }
    }

    return (
        <>
            <Header />

            <div className="dir-rtl">
                <div className="login-sec" >
                    <div className="row text-center">
                        <h2 className="title-32">{t("Reset your password")}</h2>
                    </div>
                    <div className="container">
                        <div className="row marg-login justify-content-center">
                            <div className="col-md-7">
                                <form className="signup-form" onSubmit={handleChangePassword}>
                                    <div className="form-group mb-3">
                                        <label htmlFor="email" className="label mb-3">{t("Email Address")}</label>
                                        <input type="email" className="form-control disabled" name="email" value={formData.email} onChange={handleChange} />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="code" className="label mb-3">{t("Code")}</label>
                                        <input type="number" className="form-control" name="code" value={formData.code} onChange={handleChange} />
                                        {errors.code && <span className="error">{t(`${errors.code}`)}</span>}
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="Password" className="label mb-3">{t("New Password")}</label>
                                        <input type="password" className="form-control" name="password" value={formData.password || ""}
                                            onChange={handleChange} />
                                        {errors.password && <span className="error">{t(`${errors.password}`)}</span>}
                                    </div>
                                    <p className="label label-666 mt-3">{t("Use 8 or more characters with")}</p>

                                    <div className="form-group mb-4">
                                        <label htmlFor="Confirm" className="label mb-3">{t("Confirm new password")}</label>
                                        <input type="password" className="form-control" value={confirmPassword || ""}
                                            onChange={handleConfirmPassword} />
                                        {errors.confirmPassword && <span className="error">{t(`${errors.confirmPassword}`)}</span>}
                                    </div>
                                    <div className="sign-a justify-content-end">
                                        <button type="submit" className="btn">{t("Save password")}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar open={openSnackbar} onClose={handleCloseSnackbar}>
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={colorSnackbar || "info"}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {t(`${message}`)}
                </Alert>
            </Snackbar>
        </>
    )
}
export default ChangePassword;