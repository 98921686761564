import axios from "axios";

const Url = process.env.REACT_APP_URl;

export interface TokenPayload {
    password: string
    email: string
}

export const login = (user: TokenPayload) => {
    return axios.post(Url + "/login", user).then((response) => {
        localStorage.setItem('token_user', response.data.token)
        return response.data;
    });
};

export const CreateAccount = (user: any) => {
    return axios.post(Url + "/accounts", user).then((response) => {
        return response.data;
    });
};

export const CreateAccountGoogle = (token: any) => {
    return axios.post(Url + "/api/auth/google", {token :token}).then((response) => {
        localStorage.setItem('token_user', response.data.token)
        return response.data;
    });
};

export const ServForgotPassword = (email: any) => {
    return axios.post(Url + "/forgot-password", { email: email }).then((response) => {
        return response.data;
    });
};

export const ServResetPassword = (obj: any) => {
    return axios.post(Url + "/reset-password", obj).then((response) => {
        return response.data;
    });
};

export const GetAccount = async () => {
    const apiUrl = Url + "/accounts";
    const response = await axios.get(`${apiUrl}`, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token_user")
        }
    })
    return response.data;
}

export const UpdateAccount = async (obj: any) => {
    const apiUrl = Url + "/accounts";
    const response = await axios.put(`${apiUrl}`, obj, {
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token_user")
        }
    })
    return response.data;
}

export const saveToken = (token: any) => {
    localStorage.setItem('token_user', token)
}

export const logout = () => {
    localStorage.removeItem('token_user')
}

export const isLogged = () => {
    let token = localStorage.getItem('token_user')
    return !!token
}