import React, { useState } from "react";
import { CreateAccountGoogle } from "../../Services/Authentication";
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from "react-i18next";


const BuutonGoogle: React.FunctionComponent<{ onCreateAccount: any ,text :any}> = (props) => {

    const { t } = useTranslation();

    const HandleClickGoogle = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const token = tokenResponse.access_token;
            props.onCreateAccount(token)
        },
        flow: 'implicit', // You can use 'auth-code' if you want server-side token exchange
    });

    return (
        <>
            <button type="button" className="sign-google" onClick={() => HandleClickGoogle()}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 488 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path></svg>
                {t(`${props.text}`)}
            </button>


        </>
    )
}
export default BuutonGoogle;