import React, { useEffect, useState } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from './layout/header';
import { useTranslation } from 'react-i18next';
const Url = process.env.REACT_APP_URl;

function VerifyAccount() {

  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("Verifying...");
  const [isVerified, setIsVerified] = useState(false);
  const token = searchParams.get("token");
  const navigate = useNavigate();
    const { t } = useTranslation();

  const verifyAccount = async () => {
    try {
      const response = await axios.get(`${Url}/verify?token=${token}`);
      if (response.data.message === "Account verified successfully") {
        setMessage(response.data.message);
        setIsVerified(true); // Update state to show the login link
      } else {
        setIsVerified(true);
        setMessage(response.data.error || "Failed to verify account.");
      }
    } catch (resp: any) {
      setIsVerified(true);
      setMessage(resp.response.data.error);
    }
  };

  return (
    <div className="dir-rtl">
      <Header />
        <div className="container">
          <div className='img-logo '>
            <img src="/assets/images-v2/logo.svg" alt="Email Icon" />
          </div>
          <div className='signup-form card-verify' >
            {isVerified === false ? <h1 className='title mb-4'>{t("Verify Your Email Address")} </h1 > : <h1 className='title mb-4'>{t(`${message}`)}</h1>}
            {isVerified === false && <p>
              {t("Hi , Welcome to Design Any Room!")}
            </p>}
            {isVerified === false ? <button type="submit" className="btn text-center mt-3" onClick={verifyAccount}>{t("Verify My Email")}</button>
              : <button type="submit" className="btn text-center mt-3" onClick={() => navigate("/login")}> {t("Go to Login")}</button>}
          </div>
        </div>
    </div>
  );
}

export default VerifyAccount;
