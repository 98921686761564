import React from 'react';
import { useTranslation } from "react-i18next";
import Header from './layout/header';

const PrivacyPolicy: React.FunctionComponent<{}> = (props) => {

    const { t } = useTranslation();


    return (
        <>
            <Header />

            <div className=" dir-rtl terms">
                <div className="container">
                    <div className="text-center">
                        <h1 className='h1-title'>{t("Privacy Policy")}</h1>
                    </div>
                    <div className="row">
                        <div className='col-md-12'>
                            <h2 className="title mt-4">{t("Effective Date:")} 21/11/2024</h2>
                            <p className='desc'>{t("DesignAnyRoom values your privacy")}</p>
                            <h2 className="title mt-4">1. {t("Information We Collect")}</h2>
                            <p className='desc'>1.1 <span style={{ fontWeight: "700" }}>{t("Personal Information")} : </span>{t("Includes your name, email")}</p>
                            <p className='desc'>1.2<span style={{ fontWeight: "700" }}> {t("Usage Data")} :</span> {t("Includes IP address, browser")}</p>
                            <h2 className="title mt-4">2. {t("How We Use Your Information")}</h2>
                            <p className='desc'> 2.1 {t("To provide and improve our services.")}</p>
                            <p className='desc'> 2.2 {t("To process payments and manage")}</p>
                            <p className='desc'>  2.3 {t("To communicate with you about")}</p>
                            <h2 className="title mt-4">3. {t("Cookies")}</h2>
                            <p className='desc'> 3.1 {t("We use cookies to enhance your experience")}</p>
                            <h2 className="title mt-4">4. {t("Third-Party Sharing")}</h2>
                            <p className='desc'>4.1 {t("We do not sell your information")}</p>
                            <h2 className="title mt-4">5. {t("Data Security")}</h2>
                            <p className='desc'> 5.1 {t("We use encryption and secure")}</p>
                            <p className='desc'> 5.2 {t("While we take every precaution")}</p>
                            <h2 className="title mt-4">6. {t("Your Rights")}</h2>
                            <p className='desc'>6.1 {t("You can access, modify")}</p>
                            <p className='desc'>6.2 {t("You may opt out of marketing")}</p>
                            <h2 className="title mt-4">7. {t("Children’s Privacy")}</h2>
                            <p className='desc'>7.1 {t("Our platform is not intended")}</p>
                            <h2 className="title mt-4">8. {t("International Users")}</h2>
                            <p className='desc'> 8.1 {t("By using our services, you consent")}</p>
                            <h2 className="title mt-4">9. {t("Policy Changes")}</h2>
                            <p className='desc'>9.1 {t("We may update this policy")}</p>
                            <h2 className="title mt-4">10. {t("Contact")}</h2>
                            <p className='desc'>{t("For privacy concerns")} <a href="mailto:contact@designAnyRoom.com"> contact@designAnyRoom.com </a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PrivacyPolicy;
